import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
/**Paginas para rutas */
import { Clientes } from '../../../pages/crm/clientes/Clientes'
import { ClientesCreate } from '../../../pages/crm/clientes/ClientesCreate'
import { ClientesEdit } from '../../../pages/crm/clientes/ClientesEdit'

export const ClientesRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Clientes />} />
                <Route path='/create' element={<ClientesCreate />} />
                <Route path='/edit/:id' element={<ClientesEdit />} />
            </Routes>
        </Fragment>
    )
}
