import { Tab } from '@headlessui/react'

const inventarioPatio = [
    { id: 1, Patio: 'C1', Full: '334', MTY: '5', OPR: '339', DMG: 'null', contenedor20: '123', contenedor40: '216', contenedor45: '', total: '339', Capacidad: '1544', Ocupado: '35.95', Disponible: '64.05', },
    { id: 2, Patio: 'C2', Full: '', MTY: '338', OPR: '164', DMG: '174', contenedor20: '92', contenedor40: '245', contenedor45: '1', total: '338', Capacidad: '5000', Ocupado: '11.68', Disponible: '88.32', },
    { id: 3, Patio: 'Pariata', Full: '', MTY: '794', OPR: '443', DMG: '351', contenedor20: '30', contenedor40: '763', contenedor45: '1', total: '794', Capacidad: '2834', Ocupado: '54.98', Disponible: '45.02', },
    { id: 4, Patio: 'PTG', Full: '', MTY: '215', OPR: '154', DMG: '61', contenedor20: '8', contenedor40: '206', contenedor45: '1', total: '215', Capacidad: '1500', Ocupado: '28.13', Disponible: '71.87', },
    { id: 5, Patio: 'PTV', Full: '', MTY: '221', OPR: '139', DMG: '82', contenedor20: '213', contenedor40: '8', contenedor45: '', total: '221', Capacidad: '1200', Ocupado: '19.08', Disponible: '80.92', },
]

const tab = [
    { name: 'Ultimos pagos registrados' },
    { name: 'Ultimos conectados' },
    { name: 'Resumen facturacion' },

]

export const TabTablasHome = () => {
    /**Funcion de tailwinds */
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Tab.Group>
            <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
                {tab.map((item) => (
                    <Tab
                        key={item.name}
                        className={({ selected }) =>
                            classNames(
                                'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                                null,
                                selected
                                    ? 'bg-snow shadow'
                                    : 'text-blue-100 hover:bg-snow/[0.12] hover:text-snow'
                            )
                        }
                    >
                        {item.name}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
                <Tab.Panel
                    className={classNames(
                        'bg-snow rounded-xl p-3', null
                    )}
                >
                    <table className='min-w-full divide-y divide-gray-200'>
                        <thead>
                            <tr className='bg-gray-200 font-bold'>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Patio
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    FULL
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    MTY
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-green uppercase tracking-wider'>
                                    OPR
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-red uppercase tracking-wider'>
                                    DMG
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Cont20
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Cont40
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Cont45
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Total Contenedores
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Capacidad
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Ocupado
                                </th>
                                <th className='px-5 py-3  text-center text-xs text-gray-dark uppercase tracking-wider'>
                                    Disponible
                                </th>
                            </tr>
                        </thead>
                        <tbody className='bg-snow divide-y divide-gray-200'>
                            {inventarioPatio.map((item) => (
                                <tr key={item.id} className='bg-snow hover:bg-gray-50'>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.Patio} </div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.Full}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.MTY}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.OPR}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.DMG}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.contenedor20}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.contenedor40}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.contenedor45}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.total}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.Capacidad}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.Ocupado}</div>
                                    </td>
                                    <td className='px-2 py-3 text-center snowspace-nowrap text-sm text-gray-dark'>
                                        <div className='text-gray-900 font-medium'>{item.Disponible}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Tab.Panel>

                {/* {Object.values(categories).map((posts, idx) => (
                    <Tab.Panel
                        key={idx}
                        className={classNames(
                            'bg-snow rounded-xl p-3',
                            'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-snow ring-opacity-60'
                        )}
                    >
                        <ul>
                            {posts.map((post) => (
                                <li
                                    key={post.id}
                                    className="relative p-3 rounded-md hover:bg-coolGray-100"
                                >
                                    <h3 className="text-sm font-medium leading-5">
                                        {post.title}
                                    </h3>

                                    <ul className="flex mt-1 space-x-1 text-xs font-normal leading-4 text-coolGray-500">
                                        <li>{post.date}</li>
                                        <li>&middot;</li>
                                        <li>{post.commentCount} comments</li>
                                        <li>&middot;</li>
                                        <li>{post.shareCount} shares</li>
                                    </ul>

                                    <a
                                        href="#"
                                        className={classNames(
                                            'absolute inset-0 rounded-md',
                                            'focus:z-10 focus:outline-none focus:ring-2 ring-blue-400'
                                        )}
                                    />
                                </li>
                            ))}
                        </ul>
                    </Tab.Panel>
                ))} */}
            </Tab.Panels>
        </Tab.Group>
    )
}
