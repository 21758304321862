import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**paginas para las rutas */
import { Articulos } from '../../../pages/almacen/articulos/Articulos'
import { ArticulosCreate } from '../../../pages/almacen/articulos/ArticulosCreate'
import { ArticulosEdit } from '../../../pages/almacen/articulos/ArticulosEdit'



export const ArticulosRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Articulos />} />
                <Route path='/create' element={<ArticulosCreate />} />
                <Route path='/edit/:id' element={<ArticulosEdit />} />
            </Routes>
        </Fragment>
    )
}
