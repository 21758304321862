import { Fragment, useState, useContext } from 'react';
/** Router Dom V6 */
import { Link, useNavigate } from 'react-router-dom';
/**Elementos de Tailwinds */
import { Menu, Transition } from '@headlessui/react';
/** Iconos */
import { FiLogOut, FiUser, FiLock } from "react-icons/fi";
/** Reducers */
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/types';
/** Ruta del Backend */
import { endpoint } from '../../constants/endpoint';
/** Cookie */
import CookieService from '../../services/CookieService';
/** Axios */
import axios from 'axios';
/** Componente loading screen */
import { LoadingScreen } from '../loadings/LoadingScreen';
import { colorLetraMain } from '../../constants/helpers/colorsHomeUser';

export const MenuUser = () => {
    const [openMain, setOpenMain] = useState(false);
    const { dispatch } = useContext(AuthContext)

    /** Cargando de la funcion handleLogout */
    const [cargando, setCargando] = useState(false)

    /** Variable para navegar */
    const navigate = useNavigate();

    const { user, token } = CookieService.get('user');

    /** Funcion para logout */
    const handleLogout = () => {
        setOpenMain(false) /** Cerrar el menu */
        setCargando(true)
        const url = `${endpoint}/logout/`;
        setTimeout(async () => {
            await axios.get(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            }).then(() => {
                /** Eliminamos el Token */
                CookieService.remove('user');
                /** Trigger Logout */
                dispatch({ type: types.logout })
                navigate('/login', {
                    replace: true
                })
            }).catch((err) => {
                setCargando(false)
                console.log(err)
            })
        }, 500)

    }

    /** Navegacion del menu */
    const userNavigation = [
        { name: 'Usuarios', href: '/users', icon: () => <FiUser />, current: false },
        { name: 'Roles - Permisos', href: '/roles', icon: () => <FiLock />, current: false },
    ]

    return (
        <Fragment>
            <LoadingScreen
                ImgClass='animate-spin'
                cargando={cargando}
            />

            <Menu as='div' className='ml-3 relative'>

                <div>
                    <button
                        onClick={() => setOpenMain(!openMain)}
                        className={`${colorLetraMain[user.nombre.charAt(0).toUpperCase()]}  bg-snow p-2.5 rounded-full h-8 w-8 text-center flex items-center mx- text-base`}
                    >
                        {user.nombre.charAt(0).toUpperCase()}
                    </button>
                </div>

                <Transition
                    as={Fragment}
                    show={openMain}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >

                    <Menu.Items static className='z-50 origin-top-right absolute right-0 mt-2 w-52 shadow-lg py-fit
                     bg-snow ring-1 ring-black ring-opacity-5 focus:outline-none rounded-lg'>

                        <div className='block p-fit mt-2 bg-snow rounded-lg'>

                            <div className='text-center'>
                                <p className='pt-2 text-lg font-semibold'>
                                    Nombre
                                </p>

                                <p className='text-xs text-black'>
                                    No posee email
                                </p>

                                <p className='text-xs text-black'>No posee empresa</p>

                                <p className='text-xs text-black mb-2'>
                                    No hay Taquilla asignada
                                </p>
                            </div>

                            <hr />

                            {userNavigation.map((item, index) => (
                                <Menu.Item key={item.name}>
                                    {({ active }) => (
                                        <Link
                                            key={index.toString()}
                                            to={item.href}
                                            className={`block text-left px-4 py-2 text-sm text-blue font-bold hover:bg-blue-light hover:text-snow ease-linear transition-all duration-50 `}
                                        >
                                            <div className='inline-flex'>
                                                <span className='mr-2'>
                                                    <item.icon />
                                                </span>
                                                {item.name}
                                            </div>
                                        </Link>
                                    )}
                                </Menu.Item>
                            ))}

                            <Menu.Item>
                                {({ active }) => (
                                    <div>
                                        <button
                                            type='button'
                                            onClick={() => handleLogout()}
                                            className={`rounded-b-md inline-flex w-full text-left px-4 py-2 text-sm text-ruby hover:bg-rubyDark hover:text-snow font-bold ease-linear transition-all duration-50`}
                                        >
                                            <span className='px-2 py-1'>
                                                <FiLogOut />
                                            </span>
                                            Salir
                                        </button>
                                    </div>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>

            </Menu>
        </Fragment>
    );
};
