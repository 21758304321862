import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**paginas de la rutas */
import { Kits } from '../../../pages/almacen/kits/Kits'
import { KitsCreate } from '../../../pages/almacen/kits/KitsCreate'
import { KitsEdit } from '../../../pages/almacen/kits/KitsEdit'

export const KitsRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Kits />} />
                <Route path='/create' element={<KitsCreate />} />
                <Route path='/edit/:id' element={<KitsEdit />} />
            </Routes>
        </Fragment>
    )
}
