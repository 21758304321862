import { useState, Fragment, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form';
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiEdit3 } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Manejo de errores */
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/** Float Input Component (Personalizado) */
import { FloatInputText } from '../../../components/inputs/FloatInputText';
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { SubmitLayout } from '../../../components/button/group/SubmitLayout';
import { Switch } from '@headlessui/react';


export const ArticulosEdit = () => {

  /**Location del objecto para editar*/
  const { state } = useLocation()
  console.log(state)
  /**Navegar a otra ruta */
  let navigate = useNavigate();
  /**Manejo de dato en los inputs y el submit */
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  /**Obtener el Token */
  const { token } = CookieService.get('user');
  /**Estado de Loading */
  const [cargando, setCargando] = useState(false);
  /**activo o no */
  const [activo, setActivo] = useState(state.asignable)

  const [tipos, setTipos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [fabricantes, setFabricantes] = useState([]);
  const [unidades, setUnidades] = useState([])


  /**Obtener los modelos */
  const getFormularioArticulo = async () => {
    setCargando(true)
    const url = `${endpoint}/api/articulos/formularios/`;
    await axios.get(url, headers(token)).then((response) => {
      /** Asignar Data a la variable */
      setTipos(response.data.tipos)
      setMarcas(response.data.marcas)
      setFabricantes(response.data.fabricantes)
      setUnidades(response.data.unidades)
      /** Cargando */
      setCargando(false)
    }).catch(() => {
      toast.warning(`Opps, Ha ocurrido un error`, topRight);
      setCargando(false)
    })
  }



  useEffect(() => {
    getFormularioArticulo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**Funcion de submit */
  const onSubmit = async (data) => {
    let resultado = { ...data, asignable: activo }
    setCargando(true)
    const url = `${endpoint}/api/articulos/${state.id}/`;
    await axios.put(url, resultado, headers(token)).then(() => {
      toast.success(`La Marca ${data.modelo} fue editada con exito!`, topRight);
      navigate('/articulos')
    }).catch((err) => {
      const error = ErrorValidation(err.response);
      toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
      setCargando(false);
    })
  };




  return (
    <Fragment>
      {cargando === true && (
        <LoadingScreen
          ImgClass={`animate-pulse`}
          cargando={cargando}
        />
      )}
      <div className='flex flex-col p-10'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='p-4'>
            <h1 className='p-2 text-2xl text-blue-dark'>Editar el Articulo {state.tipo + ' ' + state.fabricante}</h1>
            <div className='p-2 text-xs text-gray-dark'>Todos los campos con el <span className='text-ruby font-bold'>(*)</span> son requeridos</div>
            <hr />
          </div>


          <div className='py-2 px-4 align-middle inline-block min-w-full'>
            <div className='px-2 py-8  shadow overflow-hidden border sm:rounded-lg w-full bg-snow'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-6 gap-6'>

                  {tipos.length > 0 && (
                    <div className='col-span-6 sm:col-span-2'>
                      <select
                        id='tipos'
                        autoComplete='tipos'
                        className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                        {...register('tipo', { required: true })}
                        defaultValue={state.tipo_id}
                      >
                        <option value=''>Seleccione un tipo</option>
                        {tipos.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.nombre}
                          </option>
                        ))}
                      </select>
                      {errors.tipo && (
                        <div className='text-ruby pt-1 text-sm'>El campo Tipo es requerido</div>
                      )}
                    </div>
                  )}

                  {marcas.length > 0 && (
                    <div className='col-span-6 sm:col-span-2'>

                      <select
                        id='marcas'
                        autoComplete='marcas'
                        className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                        {...register('marca', { required: true })}
                        defaultValue={state.marca_id}
                      >
                        <option value=''>Seleccione una marca</option>
                        {marcas.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.codigo}
                          </option>
                        ))}
                      </select>
                      {errors.marca && (
                        <div className='text-ruby pt-1 text-sm'>El campo Marca es requerido</div>
                      )}
                    </div>

                  )}

                  {fabricantes.length > 0 && (
                    <div className='col-span-6 sm:col-span-2'>

                      <select
                        id='fabricantes'
                        autoComplete='fabricantes'
                        className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                        {...register('fabricante', { required: true })}
                        defaultValue={state.fabricante_id}
                      >
                        <option value=''>Seleccione un fabricante</option>
                        {fabricantes.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.codigo}
                          </option>
                        ))}
                      </select>
                      {errors.fabricante && (
                        <div className='text-ruby pt-1 text-sm'>El campo Fabricante es requerido</div>
                      )}
                    </div>

                  )}

                 


                  {unidades.length > 0 && (
                    <div className='col-span-6 sm:col-span-2'>
                      <select
                        id='unidades'
                        autoComplete='unidades'
                        className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                        {...register('unidad', { required: true })}
                        defaultValue={state.unidad}
                      >
                        <option value=''>Seleccione una unidad</option>
                        {unidades.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.nombre}
                          </option>
                        ))}
                      </select>
                      {errors.unidad && (
                        <div className='text-ruby pt-1 text-sm'>El campo Unidad es requerido</div>
                      )}
                    </div>
                  )}


                  <div className='col-span-6 sm:col-span-2 relative'>
                    <FloatInputText
                      validation={register('descripcion', { required: true })}
                      name={'Descripcion'}
                      showName={'Descripcion'}
                      value={state.descripcion}
                    />
                    {errors.descripcion && (
                      <div className='text-ruby pt-1 text-sm'>El campo Descripcion es requerido</div>
                    )}
                  </div>

                  <div className='col-span-6 sm:col-span-2 relative'>
                    <FloatInputText
                      validation={register('modelo', { required: true })}
                      name={'Modelo'}
                      showName={'Modelo'}
                      value={state.modelo}
                    />
                    {errors.modelo && (
                      <div className='text-ruby pt-1 text-sm'>El campo modelo es requerido</div>
                    )}
                  </div>

                  <div className='col-span-6 sm:col-span-2 relative'>
                    <FloatInputText
                      validation={register('ciot', { required: true })}
                      name={'CIOT'}
                      showName={'CIOT'}
                      value={state.ciot}
                    />
                    {errors.ciot && (
                      <div className='text-ruby pt-1 text-sm'>El campo CIOT es requerido</div>
                    )}
                  </div>


                  <div className='col-span-6 sm:col-span-2 relative'>
                    <Switch.Group>
                      <div className='mt-4 flex items-center'>
                        <Switch.Label className='mr-4 text-black text-sm'>Consumible <span className='text-ruby font-bold'>(*)</span> :</Switch.Label>
                        <Switch
                          checked={activo}
                          onChange={setActivo}
                          name='activo'
                          className={`${activo ? 'bg-snow-light' : 'bg-snow-dark'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
                        >
                          <span className={`${activo ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`} />
                        </Switch>

                        {activo ? (
                          <p className='ml-2'>
                            <span className='inline-flex text-xs leading-5 font-semibold text-emerald tex'>
                              Habilitado
                            </span>
                          </p>
                        ) : (
                          <p className='ml-2'>
                            <span className='inline-flex text-xs leading-5 font-semibold text-ruby tex'>
                              Deshabilitado
                            </span>
                          </p>
                        )}
                      </div>
                    </Switch.Group>
                  </div>


                </div>


                <div className='mt-5 py-2 text-right'>
                  {/* Botones de Cancelar, Limpiar y Submit */}
                  <SubmitLayout
                    backRoute={`/articulos`}
                    reset={reset}
                    submitName={`Editar`}
                    SubmitIcon={() => <FiEdit3 className='ml-2 mt-0.5 text-blue' />}
                  />
                </div>
              </form>
            </div>
          </div>

        </div >
      </div>

    </Fragment>
  )
}
