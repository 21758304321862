export const headers = (token, body = null) => {
    var headerBody = {}

    if (token) {
        headerBody.headers = { ...headerBody.headers, 'Authorization': `Token ${token}` }
    }

    if (body) {
        Object.assign(headerBody.headers, { ...body })
    }

    return headerBody;
}