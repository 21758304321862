export const images = {
    logoNetwork: require('../images/gnetworkisotipo.png'),
    logoNetwork2: require('../images/gnetworkisotipo2.png'),
    logoNetwork3: require('../images/gnetworklogo.png'),
    logoNetwork4: require('../images/gnetworklogo2.png'),
    logoNetwork5: require('../images/gnetworklogo3.png'),
    fondo1: require('../images/fondo1.png'),
    fondo2: require('../images/fondo2.png'),
    fondo3: require('../images/fondo3.png'),
    fondo4: require('../images/fondo4.png'),
}