import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**rutas para las paginas */
import { Fabricantes } from '../../../pages/almacen/fabricantes/Fabricantes'
import { FabricantesCreate } from '../../../pages/almacen/fabricantes/FabricantesCreate'
import { FabricantesEdit } from '../../../pages/almacen/fabricantes/FabricantesEdit'


export const FabricantesRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Fabricantes />} />
                <Route path='/create' element={<FabricantesCreate />} />
                <Route path='/edit/:id' element={<FabricantesEdit />} />
            </Routes>
        </Fragment>
    )
}
