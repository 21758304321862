/** Inicio Validar response error del backend */
export const ErrorValidation = (err) => {
    const value = capitalizeFirstLetter(getKey(err.data).replace('_', ' '))
    const first = getKey(err.data)

    if (err.data[first] === 'Usted no tiene permiso para realizar esta acción.') {
        return err.data[first];
    } else {
        const error = descapitalizeFirstLetter(err.data[first][0])

        return `${value} - ${error} `;
    }

}

/** Obtener el primer Key de un array, sinonimo de [0] */
function getKey(data) {
    for (var prop in data)
        return prop;
}

/** Volver Mayuscula la primera letra */
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/** Volver Minuscula la primera letra */
function descapitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}