export const SearchComponent = ({ divClassName = '', inputClassName = '', onChange, placeholder }) => {
    return (
        <div className={`flex, ${divClassName}`}>
            <input
                type='text'
                name='search'
                id='search'
                placeholder={placeholder}
                onChange={onChange}
                className={`shadow-sm block w-auto px-5 py-2 sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-gray-400 focus:ring-transparent ${inputClassName}`}
            />
        </div>
    );
}