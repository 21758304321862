import { CheckCircleIcon, CubeIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { msjBienvenida } from '../constants/helpers/welcome'
// import { BsFillTelephoneFill, BsFilePersonFill } from "react-icons/bs";
import { TabTablasHome } from '../components/home/TabTablasHome'
import CookieService from '../services/CookieService';
import { colorLetraMain } from '../constants/helpers/colorsHomeUser';

const cards = [
    { id: 1, nombre: 'Clientes Onlines', href: '#', icon: CubeIcon, cantidad: '1907', textColor: 'text-black', cubeColor: 'text-white' },
    { id: 2, nombre: 'Transacciones hoy', href: '#', icon: CubeIcon, cantidad: '334', textColor: 'text-white', cubeColor: 'text-white' },
    { id: 3, nombre: 'Facturas no pagadas', href: '#', icon: CubeIcon, cantidad: '1573', textColor: 'text-white', cubeColor: 'text-white' },
    { id: 4, nombre: 'Ticket soporte', href: '#', icon: CubeIcon, cantidad: '1239', textColor: 'text-white', cubeColor: 'text-white' },

]

/**Color Cards */
const colorCard = {
    'Clientes Onlines': 'bg-green-400/80 text-snow',
    'Transacciones hoy': 'bg-blue text-snow',
    'Facturas no pagadas': 'bg-violet-500 text-snow',
    'Ticket soporte': 'bg-black text-snow',
}

export const Home = () => {
    const { user } = CookieService.get('user');

    return (
        <div className='py-6'>
            <div className='max-w-auto mx-auto px-4 sm:px-6 md:px-8'>
                {/* Replace with your content */}
                <div className=''>
                    <main className='flex-1 relative pb-8 z-0 overflow-y-auto'>
                        {/* Page header */}
                        <div className='bg-snow shadow'>
                            <div className='px-4 sm:px-6 lg:max-w-auto lg:mx-auto lg:px-8'>
                                <div className='py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200'>
                                    <div className='flex-1 min-w-0'>
                                        {/* Profile */}
                                        <div className='flex items-center text-center'>

                                            <div className={`${colorLetraMain[user.nombre.charAt(0).toUpperCase()]} hidden h-16 w-16 py-3.5 text-2xl rounded-full sm:block`}>
                                                {user.nombre.charAt(0).toUpperCase()}
                                            </div>

                                            <div>
                                                <div className='flex items-center'>
                                                    <div className={`${colorLetraMain[user.nombre.charAt(0).toUpperCase()]} h-16 w-16 py-3.5 text-2xl  rounded-full sm:hidden`}>
                                                        {user.nombre.charAt(0).toUpperCase()}
                                                    </div>

                                                    <h1 className='ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate'>
                                                        {msjBienvenida()}, {user.nombre}
                                                    </h1>
                                                </div>
                                                {/* <dl className='mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                                                    <dt className='sr-only'>Company</dt>
                                                    <dd className='flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6'>
                                                        <BsFillTelephoneFill
                                                            className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                                            aria-hidden='true'
                                                        />
                                                        0414-1234567
                                                    </dd>
                                                    <dt className='sr-only'>Account status</dt>
                                                    <dd className='mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize'>
                                                        <CheckCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400' aria-hidden='true' />
                                                        Cuenta verificada
                                                    </dd>
                                                </dl> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-8'>
                            <div className='max-w-auto w-full mx-auto px-4 sm:px-6 lg:px-8'>
                                {/* <h2 className='text-lg leading-6 font-medium text-gray-900'>Overview</h2> */}
                                <div className='mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
                                    {/* Card */}
                                    {cards.map((card) => (
                                        <div key={card.id} className='transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none'>
                                            <div className={`${colorCard[card.nombre]} shadow-lg overflow-hidden  rounded-lg`}>
                                                <div className='p-5'>
                                                    <div className='flex items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <card.icon className={`${card.cubeColor} h-6 w-6`} aria-hidden='true' />
                                                        </div>
                                                        <div className='ml-5 w-0 flex-1'>
                                                            <dl>
                                                                <dt className={`${card.textColor} text-sm font-medium truncate`}>{card.nombre}</dt>
                                                                <dd>
                                                                    <div className={`${card.textColor} text-lg font-bold`}>{card.cantidad}</div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='bg-gray-50 px-5 py-3 text-black'>
                                                    <div className='text-sm text-center '>
                                                        <Link to={card.href} className='font-medium text-cyan-700 hover:text-cyan-900'>
                                                            Más información
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="w-full max-w-auto px-2 py-16 sm:px-0">
                                <TabTablasHome />
                            </div>

                        </div>
                    </main>
                </div>
                {/* /End replace */}
            </div>
        </div>
    )
}
