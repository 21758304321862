import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**paginas para las rutas */
import { Tipos } from '../../../pages/almacen/tipos/Tipos'
import { TiposCreate } from '../../../pages/almacen/tipos/TiposCreate'
import { TiposEdit } from '../../../pages/almacen/tipos/TiposEdit'

export const TiposRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Tipos />} />
                <Route path='/create' element={<TiposCreate />} />
                <Route path='/edit/:id' element={<TiposEdit />} />
            </Routes>
        </Fragment>
    )
}
