import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiEdit2, FiTrash2, FiEye, FiSlash, FiCheck } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/**Componenetes */
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { SearchComponent } from '../../../components/inputs/SearchComponent';
import { ButtonLink } from '../../../components/button/ButtonLink';
import { ModalInventarioShow } from './partials/ModalInventarioShow';
/**Validacion de errores */
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';

export const Inventario = () => {
    /**Nagvegar para otra ruta */
    let navigate = useNavigate()
    /**Estado del serach */
    const [search, setSearch] = useState('')
    /**cargando estado */
    const [cargando, setCargando] = useState(false);
    /**obtener el token */
    const { token } = CookieService.get('user');
    /**Estado de dara de inventario */
    const [inventario, setInventario] = useState([]);


    /**Funcion para obtener los inventario */
    const getInventario = async () => {
        setCargando(true)
        const url = `${endpoint}/api/inventario/`;
        await axios.get(url, headers(token)).then((response) => {
            /** Asignar Data a la variable */
            setInventario(response.data);
            /** Cargando */
            setCargando(false)
        }).catch((err) => {
            const error = ErrorValidation(err.response);
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
            setCargando(false);
        })
    }

    useEffect(() => {
        getInventario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Funcion para elimnar un inventario */
    const handleDestroy = async (item) => {
        setCargando(true)
        const url = `${endpoint}/api/inventario/${item.id}/`;
        await axios.delete(url, headers(token)).then(() => {
            /** Remover del Array sin Volver a llamar la Peticion Get */
            var myArray = [...inventario];
            var myIndex = myArray.indexOf(item);
            if (myIndex !== -1) {
                myArray.splice(myIndex, 1);
                setInventario(myArray)
            }
            toast.success(`El inventario fue Eliminado con exito!`, topRight);
            setCargando(false)
        }).catch(() => {
            setCargando(false);
        })
    };

    /**Filtro para buscar un inventario  */
    const filteredInventario = inventario.filter((value) => {
        return value.articulo_descripcion.toLowerCase().includes(search.toLowerCase())
    }).sort(function (a, b) {
        if (a.id > b.id) { return -1; }
        if (a.id < b.id) { return 1; }
        return 0;
    });

    /**Modal control */
    const [openModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState([]);

    const handleShowModal = (value) => {
        setDataModal(value)
        setOpenModal(!openModal)
    }

    return (
        <Fragment>
            {cargando === true && (
                <LoadingScreen
                    ImgClass='animate-pulse'
                    cargando={cargando}
                />
            )}
            <div className='flex flex-col p-10'>
                <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='pl-8 pb-4 pt-4 pr-4'>
                        <h1 className='pl-6 pb-2 text-2xl text-blue-dark'>Inventarios</h1>
                        <hr />
                    </div>

                    <div className='align-middle inline-block min-w-full sm:px-6 lg:px-8 '>
                        <div className='pb-5 grid grid-flow-col grid-cols-6 grid-rows row-auto gap-6'>

                            {/* Boton para nuevo registro */}
                            <div className='col-span-6 sm:col-span-2'>
                                <ButtonLink nombre={`Crear`} ruta={`/inventario/create`} buttonStyle={`hover:pr-5`} />
                            </div>

                            {/* Buscar dentro de la tabla */}
                            <div className='lg:col-end-8 md:col-end-8 sm:col-end-8 mt-4'>
                                <SearchComponent
                                    onChange={(data) => setSearch(data.target.value.substr(0, 20))}
                                    placeholder='Buscar inventario ... '
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <div className="overflow-x-auto">
                        {/* Inicio Table */}
                        <table className="min-w-full divide-y divide-gray-100">
                            <thead className='bg-blue-dark text-white font-bold'>
                                <tr>
                                    <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                                        ID
                                    </td>
                                    <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                                        Articulo
                                    </td>
                                    <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                                        Descripcion
                                    </td>
                                    <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                                        Serial
                                    </td>
                                    <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                                        Acciones
                                    </td>
                                </tr>
                            </thead>

                            <tbody className='bg-snow divide-y divide-gray-200 text-center'>
                                {filteredInventario.map((item) => (
                                    <tr key={item.id} className='hover:bg-white hover:text-blue'>
                                        <td className='px-4 py-4 whitespace-nowrap'>
                                            {item.id}
                                        </td>
                                        <td className='px-4 py-4 whitespace-nowrap'>
                                            <div className='text-xs text-gray-dark'>
                                                {item.articulo_descripcion.length > 40 ? (
                                                    <>
                                                        {item.articulo_descripcion.slice(0, 40)} <>......</>
                                                    </>
                                                ) : (
                                                    <>
                                                        {item.articulo_descripcion}
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                        <td className='px-4 py-4 whitespace-nowrap'>
                                            <div className='text-xs text-gray-dark'>
                                                {item.descripcion.length > 40 ? (
                                                    <>
                                                        {item.descripcion.slice(0, 30)} <>......</>
                                                    </>
                                                ) : (
                                                    <>
                                                        {item.descripcion}
                                                    </>
                                                )}
                                            </div>
                                        </td>

                                        <td className='px-4 py-4 whitespace-nowrap'>
                                            {item.serial ? (<div className='grid place-items-center text-gray-3'>{item.serial}</div>) : (<div className='grid place-items-center'><FiSlash className='text-ruby' /></div>)}
                                        </td>

                                        <td className='px-4 py-4 justify-center whitespace-nowrap text-sm text-gray-500 flex flex-row space-x-4'>
                                            {/* Show */}
                                            <button
                                                type='button'
                                                title='Serial/MacAdress'
                                                className='text-sm'
                                                onClick={() => handleShowModal(item)}>
                                                <FiEye className='h-6 w-6 text-gray-darker hover:text-emerald ease-linear transition-all duration-100' />
                                            </button>
                                            {/* Edit */}
                                            <button
                                                type='button'
                                                onClick={() => navigate(`/inventario/edit/${item.id}`, { state: item })} >
                                                <FiEdit2 className='h-6 w-6 text-black hover:text-yellow ease-linear transition-all duration-100' />
                                            </button>
                                            {/* Delete */}
                                            <button onClick={() => {
                                                if (window.confirm('¿Desea eliminar el articulo actual?')) {
                                                    handleDestroy(item);
                                                }
                                            }}>
                                                <FiTrash2 className='h-6 w-6 text-black hover:text-ruby ease-linear transition-all duration-100' />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* Fin Table */}
                        <ModalInventarioShow
                            data={dataModal}
                            open={openModal}
                            setOpen={setOpenModal}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
