import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiPlus } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/**Componenetes */
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';
import { FloatInputText } from '../../../components/inputs/FloatInputText';
import { SubmitLayout } from '../../../components/button/group/SubmitLayout';

export const SectoresCreate = () => {


  /**Navegar a otra ruta */
  let navigate = useNavigate();
  /**Manejo de dato en los inputs y el submit */
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  /**Obtener el Token */
  const { token } = CookieService.get('user');
  /**Estado de Loading */
  const [cargando, setCargando] = useState(false);
  /**Manejo de esto de Sectores */
  const [parroquias, setParroquias] = useState([])


  /**Obtener los modelos */
  const getParroquias = async () => {
    setCargando(true)
    const url = `${endpoint}/api/parroquias/`;
    await axios.get(url, headers(token)).then((response) => {
      /** Asignar Data a la variable */
      setParroquias(response.data)
      setCargando(false)
    }).catch((err) => {
      const error = ErrorValidation(err.response);
      toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
      setCargando(false);
    })
  }



  useEffect(() => {
    getParroquias()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**Funcion de submit */
  const onSubmit = async (data) => {
    setCargando(true)
    const url = `${endpoint}/api/sectores/`;
    await axios.post(url, data, headers(token)).then(() => {
      toast.success(`El sector fue creado con exito!`, topRight);
      navigate('/sectores')
    }).catch((err) => {
      const error = ErrorValidation(err.response);
      toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
      setCargando(false);
    })
  };



  return (
    <Fragment>
      {cargando === true && (
        <LoadingScreen
          ImgClass={`animate-pulse`}
          cargando={cargando}
        />
      )}
      <div className='flex flex-col p-10'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='p-4'>
            <h1 className='p-2 text-2xl text-blue-dark'>Crear un nuevo Sector</h1>
            <div className='p-2 text-xs text-gray-dark'>Todos los campos con el <span className='text-ruby font-bold'>(*)</span> son requeridos</div>
            <hr />
          </div>


          <div className='py-2 px-4 align-middle inline-block min-w-full'>
            <div className='px-2 py-8  shadow overflow-hidden border sm:rounded-lg w-full bg-snow'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-6 gap-6'>

                  <div className='col-span-6 sm:col-span-2 relative'>
                    <FloatInputText
                      validation={register('nombre', { required: true })}
                      name={'Nombre'}
                      showName={'Nombre'}
                    />
                    {errors.nombres && (
                      <div className='text-ruby pt-1 text-sm'>El campo nombres es requerido</div>
                    )}
                  </div>

                  

                  <div className='col-span-6 sm:col-span-2'>

                    <select
                      id='parroquia'
                      autoComplete='parroquia'
                      className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                      {...register('parroquia', { required: true })}
                    >
                      <option value=''>Seleccione un sector</option>
                      {parroquias.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                    {errors.parroquia && (
                      <div className='text-ruby pt-1 text-sm'>El campo parroquia es requerido</div>
                    )}
                  </div>

                
                </div>


                <div className='mt-5 py-2 text-right'>
                  {/* Botones de Cancelar, Limpiar y Submit */}
                  <SubmitLayout
                    backRoute={`/sectores`}
                    reset={reset}
                    submitName={`Crear`}
                    SubmitIcon={() => <FiPlus className='ml-2 mt-0.5 text-emerald' />}
                  />
                </div>
              </form>
            </div>
          </div>

        </div >
      </div>

    </Fragment>
  )
}
