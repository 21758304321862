import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'


export const ModalsParroquiasShow = ({ open, setOpen, data }) => {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block align-bottom bg-snow rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full'>
                            <Dialog.Title
                                as='h3'
                                className='text-lg  leading-6 text-black pl-5 pb-2 pt-4 inline-flex mb-4'
                            >
                                <span className='mr-2'></span> Parroquia - <span className='font-bold'> {data.nombre}</span>
                            </Dialog.Title>

                            <Fragment>
                                <table className='min-w-full divide-y divide-gray-100'>
                                    <thead className='bg-blue text-white font-bold'>
                                        <tr className='text-center text-xs'>
                                            <td className='px-4 py-3'>
                                                Sectores
                                            </td>

                                        </tr>
                                    </thead>
                                    <tbody className='bg-snow divide-y divide-gray-200 text-center'>

                                        <Fragment>
                                            {data.sector?.map((i, index) => (
                                                <tr key={index.toString()} className='hover:bg-white-dark'>
                                                    <td className='px-4 py-4 whitespace-nowrap'>
                                                        <div className='text-sm text-gray-dark'>{i.nombre}</div>
                                                    </td>

                                                </tr>
                                            ))}
                                        </Fragment>
                                        <Fragment>
                                            {data.sector?.length === undefined && (
                                                <tr className='hover:bg-white-dark'>
                                                    <td className='px-4 py-4 whitespace-nowrap' colSpan={4}>
                                                        <div className='text-base text-gray-dark'>La empresa no posee surcusal</div>
                                                    </td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    </tbody>
                                </table>
                            </Fragment>



                            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                <button
                                    type='button'
                                    onClick={() => setOpen(false)}
                                    className='mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-ruby hover:bg-red-600'
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
