import { BellIcon, MenuAlt2Icon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'

import { MenuUser } from './MenuUser'

export const Content = ({ setSidebarOpen, children }) => {
    return (
        <div className='flex flex-col w-0 flex-1 overflow-hidden'>

            <div className='relative z-10 flex-shrink-0 flex h-16 bg-snow shadow'>
                <button
                    type='button'
                    className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className='sr-only'>Open sidebar</span>
                    <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
                </button>
                <div className='flex-1 px-4 flex justify-between'>
                    <div className='flex-1 flex'>
                        <form className='w-full flex md:ml-0' action='#' method='GET'>
                            <label htmlFor='search-field' className='sr-only'>
                                Search
                            </label>
                            <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                                <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                                    <SearchIcon className='h-5 w-5' aria-hidden='true' />
                                </div>
                                <input
                                    id='search-field'
                                    className='block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                                    placeholder='Search'
                                    type='search'
                                    name='search'
                                />
                            </div>
                        </form>
                    </div>
                    <div className='ml-4 flex items-center md:ml-6'>
                        <button
                            type='button'
                            className='bg-snow p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                            <span className='sr-only'>View notifications</span>
                            <BellIcon className='h-6 w-6' aria-hidden='true' />
                        </button>
                        {/* Profile dropdown menu*/}
                        <MenuUser />

                    </div>
                </div>
            </div>

            {children}
        </div>
    )
}
