import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**Rutas para las paginas */
import { Marcas } from '../../../pages/almacen/marcas/Marcas'
import { MarcasCreate } from '../../../pages/almacen/marcas/MarcasCreate'
import { MarcasEdit } from '../../../pages/almacen/marcas/MarcasEdit'


export const MarcasRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Marcas />} />
                <Route path='/create' element={<MarcasCreate />} />
                <Route path='/edit/:id' element={<MarcasEdit />} />
            </Routes>
        </Fragment>
    )
}
