import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiEdit2, FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/**Componenetes */
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { SearchComponent } from '../../../components/inputs/SearchComponent';
import { ButtonLink } from '../../../components/button/ButtonLink';
/**Validacion de errores */
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';
import { ModalsParroquiasShow } from './partials/ModalsParroquiasShow';

export const Parroquias = () => {
  /**Nagvegar para otra ruta */
  let navigate = useNavigate()
  /**Estado del serach */
  const [search, setSearch] = useState('')
  /**cargando estado */
  const [cargando, setCargando] = useState(false);
  /**obtener el token */
  const { token } = CookieService.get('user');
  /**Estado para la data de las parroquias */
  const [parroquias, setParroquias] = useState([])

  /**Funcion para obtener los parroquias */
  const getParroquias = async () => {
    setCargando(true)
    const url = `${endpoint}/api/parroquias/`;
    await axios.get(url, headers(token)).then((response) => {
      /** Asignar Data a la variable */
      setParroquias(response.data);
      /** Cargando */
      setCargando(false)
    }).catch((err) => {
      const error = ErrorValidation(err.response);
      toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
      setCargando(false);
    })
  }

  useEffect(() => {
    getParroquias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**Funcion para elimnar un parroquias */
  const handleDestroy = async (item) => {
    setCargando(true)
    const url = `${endpoint}/api/parroquias/${item.id}/`;
    await axios.delete(url, headers(token)).then(() => {
      /** Remover del Array sin Volver a llamar la Peticion Get */
      var myArray = [...parroquias];
      var myIndex = myArray.indexOf(item);
      if (myIndex !== -1) {
        myArray.splice(myIndex, 1);
        setParroquias(myArray)
      }
      toast.success(`La parroquia ${item.nombre} fue Eliminado con exito!`, topRight);
      setCargando(false)
    }).catch(() => {
      setCargando(false);
    })
  };

  /**Filtro para buscar un articulos  */
  const filteredPArroquias = parroquias.filter((value) => {
    return value.nombre.toLowerCase().includes(search.toLowerCase())
  }).sort(function (a, b) {
    if (a.id > b.id) { return -1; }
    if (a.id < b.id) { return 1; }
    return 0;
  });

  /**Modal control */
  const [openModal, setOpenModal] = useState(false)
  const [dataModal, setDataModal] = useState([]);

  const handleShowModal = (value) => {

    setDataModal(value)
    setOpenModal(!openModal)

  }


  return (
    <Fragment>
      {cargando === true && (
        <LoadingScreen
          ImgClass='animate-pulse'
          cargando={cargando}
        />
      )}
      <div className='flex flex-col p-10'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='pl-8 pb-4 pt-4 pr-4'>
            <h1 className='pl-6 pb-2 text-2xl text-blue-dark'>Parroquias</h1>
            <hr />
          </div>

          <div className='align-middle inline-block min-w-full sm:px-6 lg:px-8 '>
            <div className='pb-5 grid grid-flow-col grid-cols-6 grid-rows row-auto gap-6'>

              {/* Boton para nuevo registro */}
              <div className='col-span-6 sm:col-span-2'>
                <ButtonLink nombre={`Crear`} ruta={`/parroquias/create`} buttonStyle={`hover:pr-5`} />
              </div>

              {/* Buscar dentro de la tabla */}
              <div className='lg:col-end-8 md:col-end-8 sm:col-end-8 mt-4'>
                <SearchComponent
                  onChange={(data) => setSearch(data.target.value.substr(0, 20))}
                  placeholder='Buscar parroquia ... '
                />
              </div>
            </div>
          </div>
        </div>

        <div className=' mt-6 z-0 shadow relative border-b border-gray-200 sm:rounded-lg'>
          <div className='overflow-x-auto rounded-md'>
            {/* Inicio Table */}
            <table className='min-w-full divide-y divide-gray-100'>
              <thead className='bg-blue-dark text-white font-bold'>
                <tr>
                  <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                    ID
                  </td>
                  <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                    Nombre
                  </td>
                  <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                    Sectores
                  </td>
                  <td className='px-4 py-4 text-center text-xs font-semibold text-gray-500 tracking-wider'>
                    Acciones
                  </td>
                </tr>
              </thead>

              <tbody className='bg-snow divide-y divide-gray-200 text-center'>
                {filteredPArroquias.map((item) => (
                  <tr key={item.id} className='hover:bg-white hover:text-blue break-all'>
                    <td className='px-3 py-4 whitespace-nowrap'>
                      {item.id}
                    </td>
                    <td className='px-3 py-4 whitespace-nowrap'>
                      <div className='text-sm text-gray-dark'>{item.nombre}</div>
                    </td>
                    <td className='px-3 py-4 whitespace-nowrap'>
                      {item.sector.length > 0 ? (
                        <button
                          type='button'
                          title='Ver los sectores'
                          className='text-sm'
                          onClick={() => handleShowModal(item)}>
                          <FiEye className='h-6 w-6 text-gray-darker hover:text-emerald ease-linear transition-all duration-100' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='text-sm'
                          title='No hay sectores agregados'
                        >
                          <FiEyeOff className='h-6 w-6 text-gray-darker hover:text-ruby-dark ease-linear transition-all duration-100' />
                        </button>
                      )}
                    </td>

                    <td className='px-3 py-4 justify-center whitespace-nowrap text-sm text-gray-500 flex flex-row space-x-4'>
                      <div>
                        <button
                          type='button'
                          onClick={() => navigate(`/parroquias/edit/${item.id}`, { state: item })} >
                          <FiEdit2 className='h-6 w-6 text-black hover:text-yellow ease-linear transition-all duration-100' />
                        </button>
                      </div>
                      <div>
                        <button onClick={() => {
                          if (window.confirm('¿Desea eliminar el articulo actual?')) {
                            handleDestroy(item);
                          }
                        }}>
                          <FiTrash2 className='h-6 w-6 text-black hover:text-ruby ease-linear transition-all duration-100' />

                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Fin Table */}

            <ModalsParroquiasShow
              data={dataModal}
              open={openModal}
              setOpen={setOpenModal}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
