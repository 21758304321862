import { Fragment, useState } from 'react'
import { Content } from '../components/menu/Content'
import { Sidebar } from '../components/menu/Sidebar'
import { AppRouter } from './AppRouter'


export const Main = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <Fragment>
            <div className='h-screen flex overflow-hidden bg-gray-100'>
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
                    <Content sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
                        <main className='flex-1 relative overflow-y-auto focus:outline-none'>
                            <AppRouter />
                        </main>
                    </Content>
                </Sidebar>
            </div>
        </Fragment>
    )
}
