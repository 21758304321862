import { Route, Routes } from 'react-router-dom'

/**Pagina del Home */
import { Home } from '../pages/Home'
/**Pagina de articulos */
import { ArticulosRouter } from '../routers/almacen/articulos/ArticulosRouter'
/**Pagina de tipos de articulos */
import { TiposRouter } from './almacen/tipos/TiposRouter'
/**Pagina Fabricante de articulos */
import { FabricantesRouter } from './almacen/fabricantes/FabricantesRouter'
/**Pagina Marca de articulos */
import { MarcasRouter } from './almacen/marcas/MarcasRouter'
/**Pagina  de unidades */
import { UnidadesRouter } from './almacen/unidades/UnidadesRouter'
/**Pagina de kits  */
import { KitsRouter } from './almacen/kits/KitsRouter'
/**Paginas de inventario */
import { InventarioRouter } from './almacen/inventario/InventarioRouter'
/**Pagina de clientes */
import { ClientesRouter } from './crm/clientes/ClientesRouter'
/**Pagina de Parroquias */
import { ParroquiasRouter } from './crm/parroquias/ParroquiasRouter'
/**Pagina de sectores */
import { SectoresRouter } from './crm/sectores/SectoresRouter'
/**Paginas de contratos */
import { ContratosRouter } from './crm/contratos/ContratosRouter'
/**Pagina de error */
import { Error404 } from '../pages/error/Error404'

export const AppRouter = () => {
  return (
    <Routes>
      {/* Pagina de home */}
      <Route path='/' element={<Home />} />
      {/* Pagina de articulo */}
      <Route path='articulos/*' element={<ArticulosRouter />} />
      {/* Pagina de tipos articulo */}
      <Route path='tipos/*' element={<TiposRouter />} />
      {/* Pagina de tipos marcas */}
      <Route path='marcas/*' element={<MarcasRouter />} />
      {/* Pagina de tipos fabricantes */}
      <Route path='fabricantes/*' element={<FabricantesRouter />} />
      {/* Pagina de Unidades */}
      <Route path='unidades/*' element={<UnidadesRouter />} />
      {/* Pagina de kits */}
      <Route path='kits/*' element={<KitsRouter />} />
      {/* Pagina de kits */}
      <Route path='inventario/*' element={<InventarioRouter />} />
      {/* pagina de clientes */}
      <Route path='clientes/*' element={<ClientesRouter />} />
      {/* Pagina de parroquias */}
      <Route path='parroquias/*' element={<ParroquiasRouter />} />
      {/* Pagina de sectores */}
      <Route path='sectores/*' element={<SectoresRouter />} />
      {/* Paginas de contratos */}
      <Route path='contratos/*' element={<ContratosRouter />} />
      {/* Error 404 */}
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
