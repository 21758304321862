import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
/**Libreria de Tailwidns  */
import { Switch, Transition } from '@headlessui/react';
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Errores de validacion */
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';
/**Iconos */
import { FiPlus } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/**Componenetes */
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { FloatInputText } from '../../../components/inputs/FloatInputText';
import { SubmitLayout } from '../../../components/button/group/SubmitLayout';

export const InventarioCreate = () => {
    /**Navegar a otra ruta */
    let navigate = useNavigate();
    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors }, reset, unregister, watch } = useForm({ shouldUnregister: true });
    /**Obtener el Token */
    const { token } = CookieService.get('user');
    /**Estado de Loading */
    const [cargando, setCargando] = useState(false);
    /**activo o no */
    const [activo, setActivo] = useState(false)
    const [activoMac, setActivoMac] = useState(false)

    /**Obtener los articulos */
    const [articulos, setArticulos] = useState([])

    /** Variable para identificar carga masiva segun la cantidad */
    const [masiva, setMasiva] = useState(false)
    const [actualArt, setActualArt] = useState('');

    /**Obtener los modelos */
    const getArticulos = async () => {
        setCargando(true)
        const url = `${endpoint}/api/articulos/`;
        await axios.get(url, headers(token)).then((response) => {
            /** Asignar Data a la variable */
            setArticulos(response.data)
            /** Cargando */
            setCargando(false)
        }).catch((err) => {
            const error = ErrorValidation(err.response);
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
            setCargando(false);
        })
    }

    useEffect(() => {
        getArticulos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Funcion de submit */
    const onSubmit = async (data) => {
        setCargando(true)
        const url = `${endpoint}/api/inventario/`;
        await axios.post(url, data, headers(token)).then(() => {
            toast.success(`El inventario fue creado con exito!`, topRight);
            navigate('/inventario')
        }).catch((err) => {
            const error = ErrorValidation(err.response);
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
            setCargando(false);
        })
    };

    /**Funcion para no registrar un serial*/
    const noSerialRegister = () => {
        unregister('serial');
        setActivo(false); // make sure to unmount that input so register not invoked again.
    }
    /**funcion para no regisdtrar una direccion Mac */
    const noMacAddresslRegister = () => {
        unregister('direccion_mac');
        setActivoMac(false); // make sure to unmount that input so register not invoked again.
    }

    useEffect(() => {
        if (watch('cantidad').length > 0) {
            if (watch('cantidad') > 1) {
                setMasiva(true)
            } else {
                setMasiva(false)
            }
        } else {
            setMasiva(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('cantidad')])

    const handleArtChange = (data) => {
        const id = data.target.value;
        const filteredArt = articulos.filter((value) => {
            return value.id == id;
        });
        setActualArt(filteredArt[0].descripcion);
    }

    return (
        <Fragment>
            {cargando === true && (
                <LoadingScreen
                    ImgClass={`animate-pulse`}
                    cargando={cargando}
                />
            )}
            <div className='flex flex-col p-10'>
                <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='p-4'>
                        <h1 className='p-2 text-2xl text-blue-dark'>Agregar Articulo a Inventario</h1>
                        <div className='p-2 text-xs text-gray-dark'>Todos los campos con el <span className='text-ruby font-bold'>(*)</span> son requeridos</div>
                        <hr />
                    </div>


                    <div className='py-2 px-4 align-middle inline-block min-w-full'>
                        <div className='px-2 py-8  shadow overflow-hidden border sm:rounded-lg w-full bg-snow'>
                            <form onSubmit={handleSubmit(onSubmit)}>



                                <div className='grid grid-cols-6 gap-6 mb-5'>
                                    <div className='col-span-6 sm:col-span-2'>

                                        <select
                                            id='articulos'
                                            autoComplete='articulos'
                                            className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                            {...register('articulo', { required: true })}
                                            onChange={(e) => handleArtChange(e)}
                                        >
                                            <option value=''>Seleccione un articulo</option>
                                            {articulos.map((item) => (
                                                <option value={item.id} key={item.id}>
                                                    {item.marca + ' ' + item.modelo + ' ' + item.tipo}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.tipo && (
                                            <div className='text-ruby pt-1 text-sm'>El campo Tipo es requerido</div>
                                        )}
                                    </div>


                                    <div className='col-span-6 sm:col-span-2 relative'>
                                        <FloatInputText
                                            type={'text'}
                                            validation={register('descripcion', { required: true })}
                                            name={'Descripcion'}
                                            showName={'Descripcion'}
                                            value={actualArt}
                                        />
                                        {errors.descripcion && (
                                            <div className='text-ruby pt-1 text-sm'>El campo descripcion es requerido</div>
                                        )}
                                    </div>


                                    <div className='col-span-6 sm:col-span-2 relative'>
                                        <FloatInputText
                                            type={'date'}
                                            validation={register('fecha', { required: true })}
                                            name={'Fecha'}
                                            showName={'Fecha'}
                                        />
                                        {errors.fecha && (
                                            <div className='text-ruby pt-1 text-sm'>El campo fecha es requerido</div>
                                        )}
                                    </div>

                                    <div className='col-span-6 sm:col-span-2 relative'>
                                        <FloatInputText
                                            type={'number'}
                                            validation={register('cantidad')}
                                            name={'Cantidad'}
                                            showName={'Cantidad'}
                                        />
                                        {errors.cantidad && (
                                            <div className='text-ruby pt-1 text-sm'>El campo Cantidad es requerido</div>
                                        )}
                                    </div>


                                </div>


                                {masiva === true ? (

                                    <div className='grid grid-cols-6 gap-6'>
                                        <div className='col-span-6 sm:col-span-2 relative'>
                                            <FloatInputText
                                                type={'text'}
                                                validation={register('seriales')}
                                                name={'Seriales'}
                                                showName={'Seriales'}
                                            />
                                            {errors.seriales && (
                                                <div className='text-ruby pt-1 text-sm'>El campo Seriales es requerido</div>
                                            )}
                                        </div>
                                    </div>


                                ) : (


                                    <div className='grid grid-cols-6 gap-6'>
                                        <div className='col-span-6 sm:col-span-2 relative'>
                                            <Switch.Group>
                                                <div className='mt-4 ml-2 flex items-center'>
                                                    <Switch.Label className='mr-4 text-black text-sm'>Serial :</Switch.Label>
                                                    <Switch
                                                        onClick={noSerialRegister}
                                                        name='activo'
                                                        checked={activo}
                                                        onChange={setActivo}
                                                        className={`${activo ? 'bg-snow-light' : 'bg-snow-dark'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
                                                    >
                                                        <span className={`${activo ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`} />
                                                    </Switch>

                                                    {activo ? (
                                                        <p className='ml-2'>
                                                            <span className='inline-flex text-xs leading-5 font-semibold text-emerald tex'>
                                                                Habilitado
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <p className='ml-2'>
                                                            <span className='inline-flex text-xs leading-5 font-semibold text-ruby tex'>
                                                                Deshabilitado
                                                            </span>
                                                        </p>
                                                    )}
                                                </div>
                                            </Switch.Group>
                                        </div>

                                        {activo && (
                                            <Fragment>
                                                <div className='col-span-6 sm:col-span-2 relative'>
                                                    <Switch.Group>
                                                        <div className='mt-4 ml-2 flex items-center'>
                                                            <Switch.Label className='mr-4 text-black text-sm'>MacAddress :</Switch.Label>
                                                            <Switch
                                                                onClick={noMacAddresslRegister}
                                                                name='activoMac'
                                                                checked={activoMac}
                                                                onChange={setActivoMac}
                                                                className={`${activoMac ? 'bg-snow-light' : 'bg-snow-dark'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
                                                            >
                                                                <span className={`${activoMac ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`} />
                                                            </Switch>

                                                            {activoMac ? (
                                                                <p className='ml-2'>
                                                                    <span className='inline-flex text-xs leading-5 font-semibold text-emerald tex'>
                                                                        Habilitado
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <p className='ml-2'>
                                                                    <span className='inline-flex text-xs leading-5 font-semibold text-ruby tex'>
                                                                        Deshabilitado
                                                                    </span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Switch.Group>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>



                                )}







                                <Transition
                                    show={activo}
                                    unmount={false}
                                    className='grid grid-cols-6 gap-4 mt-8'
                                    enter="transition ease-out duration-300"
                                    enterFrom="transform opacity-0"
                                    enterTo="transform opacity-100"
                                    leave="transition ease-in duration-300"
                                    leaveFrom="transform opacity-100"
                                    leaveTo="transform opacity-0"

                                >
                                    {activo && (
                                        <Fragment>
                                            <div className='col-span-6 sm:col-span-2 relative'>
                                                <FloatInputText
                                                    type={'text'}
                                                    validation={register('serial')}
                                                    name={'Serial'}
                                                    showName={'Serial'}
                                                />
                                                {errors.serial && (
                                                    <div className='text-ruby pt-1 text-sm'>El campo serial es requerido</div>
                                                )}
                                            </div>
                                        </Fragment>
                                    )}


                                    {activoMac && (
                                        <Fragment>
                                            <div className='col-span-6 sm:col-span-2 relative'>
                                                <FloatInputText
                                                    type={'text'}
                                                    validation={register('direccion_mac')}
                                                    name={'Direccion_mac'}
                                                    showName={'Direccion_mac'}
                                                />
                                                {errors.direccion_mac && (
                                                    <div className='text-ruby pt-1 text-sm'>El campo direccion_mac es requerido</div>
                                                )}
                                            </div>

                                        </Fragment>
                                    )}
                                </Transition>


                                <div className='mt-5 py-2 text-right'>
                                    {/* Botones de Cancelar, Limpiar y Submit */}
                                    <SubmitLayout
                                        backRoute={`/inventario`}
                                        reset={reset}
                                        submitName={`Crear`}
                                        SubmitIcon={() => <FiPlus className='ml-2 mt-0.5 text-emerald' />}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                </div >
            </div>

        </Fragment>
    )
}
