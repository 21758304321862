import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
/**Paginas para rutas */
import { Parroquias } from '../../../pages/crm/parroquias/Parroquias'
import { ParroquiasCreate } from '../../../pages/crm/parroquias/ParroquiasCreate'
import { ParroquiasEdit } from '../../../pages/crm/parroquias/ParroquiasEdit'

export const ParroquiasRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Parroquias />} />
                <Route path='/create' element={<ParroquiasCreate />} />
                <Route path='/edit/:id' element={<ParroquiasEdit />} />
            </Routes>
        </Fragment>
    )
}
