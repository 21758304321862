import { useState, useEffect, Fragment } from 'react'
/**Animaciones de Frame-motivo */
import { motion, useAnimation } from 'framer-motion'
/**Liberias de Tailwinds */
import { Dialog, Disclosure, Transition } from '@headlessui/react'
/**Iconos */
import { XIcon } from '@heroicons/react/outline'
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill, BsArrowRightShort } from 'react-icons/bs'
import { RiArrowDropRightFill } from 'react-icons/ri'
/**Imagenes */
import { images } from '../../constants/images'
/**Arrays de navegaciones */
import { navigationSidebar } from '../../constants/navigation/navigation'
import { Link } from 'react-router-dom'

export const Sidebar = ({ sidebarOpen, setSidebarOpen, children }) => {

    const [active, setActive] = useState(false)
    const controls = useAnimation()
    const controlText = useAnimation()
    const controlTitleText = useAnimation()

    const showMore = () => {
        controls.start({
            width: '250px',
            transition: { duration: 0.001 }
        })
        controlText.start({
            opacity: 1,
            display: 'block',
            transition: { delay: 0.3 }
        })
        controlTitleText.start({
            opacity: 1,
            transition: { delay: 0.3 }
        })

        setActive(true)
    }

    const showLess = () => {
        controls.start({
            width: '55px',
            transition: { duration: 0.001 }
        })

        controlText.start({
            opacity: 0,
            display: 'none',
        })

        controlTitleText.start({
            opacity: 0,
        })

        setActive(false)

    }

    useEffect(() => {
        showMore()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**Clases de tailwind */
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Fragment>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as='div' className='fixed inset-0 flex z-40 md:hidden' onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter='transition ease-in-out duration-300 transform'
                        enterFrom='-translate-x-full'
                        enterTo='translate-x-0'
                        leave='transition ease-in-out duration-300 transform'
                        leaveFrom='translate-x-0'
                        leaveTo='-translate-x-full'
                    >
                        <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-snow'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-in-out duration-300'
                                enterFrom='opacity-0'
                                enterTo='opacity-100'
                                leave='ease-in-out duration-300'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                            >
                                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                    <button
                                        type='button'
                                        className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className='sr-only'>Close sidebar</span>
                                        <XIcon className='h-6 w-6 text-snow' aria-hidden='true' />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className='flex-shrink-0 flex items-center px-4'>
                                <img
                                    className='h-8 w-auto'
                                    src={images.logoNetwork3}
                                    alt='G-network'
                                />
                            </div>
                            <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                                <nav className='px-2 space-y-1'>
                                    {navigationSidebar.map((item) => (
                                        !item.children ? (
                                            <div key={item.name} className={`${active && 'my-2 mx-3 rounded-md '} my-2 hover:bg-ruby hover:text-snow ease-linear transition-all duration-100 text-black-light`}>
                                                <div className='flex px-4 py-1 cursor-pointer' >
                                                    <item.icon className='h-6 w-auto text-gray-500' />
                                                    <motion.p animate={controlText} className='ml-4 text-sm font-bold' > {item.name}</motion.p>
                                                </div>
                                            </div>
                                        ) : (
                                            <Disclosure as='div' key={item.name} className={`${active && 'my-2 mx-3 rounded-md'} my-2`}>
                                                {({ open }) => (
                                                    <Fragment>
                                                        <Disclosure.Button
                                                            className='w-full flex px-4 py-1 rounded-md font-bold hover:bg-ruby hover:text-snow ease-linear transition-all duration-100 text-black-light'
                                                        >
                                                            <item.icon className='h-6 w-auto text-gray-500' />
                                                            <motion.p animate={controlText} className='ml-4 w-auto '>{item.name}</motion.p>
                                                            {active &&
                                                                <BsArrowRightShort
                                                                    className={classNames(
                                                                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                                        'ml-auto inline-flex h-6 w-auto transform transition-colors ease-in-out duration-150'
                                                                    )}
                                                                />
                                                            }
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className='space-y-1 flex flex-col'>
                                                            {item.children.map((subItem) => (
                                                                <a
                                                                    key={subItem.name}
                                                                    href={subItem.href}
                                                                    className={`w-full px-4 py-1 mx-18 hover:bg-ruby hover:text-snow ease-linear transition-all duration-100 text-black-light`}
                                                                >
                                                                    {subItem.name}
                                                                </a>
                                                            ))}
                                                        </Disclosure.Panel>
                                                    </Fragment>
                                                )}
                                            </Disclosure>
                                        )
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className='flex-shrink-0 w-14' aria-hidden='true'>
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className='hidden md:flex md:flex-shrink-0'>
                <motion.div animate={controls} className='flex flex-col w-64 relative group animate duration-500'>
                    <div className='flex-1 flex flex-col min-h-0 bg-snow'>
                        {active && <BsFillArrowLeftSquareFill onClick={showLess} className='absolute hidden text-2xl text-black-light cursor-pointer -right-4 top-10 group-hover:block z-50' />}
                        {!active && <BsFillArrowRightSquareFill onClick={showMore} className='absolute text-2xl text-black-light cursor-pointer -right-4 top-10 z-50' />}
                        <div className='flex items-center justify-center w-full py-2 my-2 '>
                            {active ? (
                                <img
                                    className='h-6 w-auto'
                                    src={images.logoNetwork3}
                                    alt='G-network'
                                />
                            ) : (
                                <img
                                    className='h-6 w-auto ml-1'
                                    src={images.logoNetwork}
                                    alt='G-network'
                                />
                            )}
                        </div>
                        <div className='space-y-1 flex-grow mt-2'>
                            <hr />

                            {navigationSidebar.map((item) => (
                                !item.children ? (
                                    <Link to='/' key={item.name}>
                                        <div className={classNames(
                                            active ? 'my-2 mx-3 rounded-md' : null,
                                            'my-2 rounded-md hover:bg-ruby hover:text-snow ease-linear transition-all duration-100 text-black-light')}
                                        >
                                            <div className='px-4 py-1 flex items-center' >
                                                <item.icon className='h-5 w-auto text-gray-500' />
                                                <motion.p animate={controlText} className='ml-4 text-xs font-bold'>{item.name}</motion.p>
                                            </div>

                                        </div>
                                    </Link>
                                ) : (
                                    <Disclosure as='div' key={item.name} className={classNames(active && 'my-2 mx-3 rounded-md', 'my-2 rounded-sm')}>
                                        {({ open }) => (
                                            <Fragment>
                                                <Disclosure.Button
                                                    className='w-full group flex items-center px-4 py-1 rounded-md font-bold hover:bg-ruby hover:text-snow ease-linear transition-all duration-100 text-black-light text-xs'
                                                >
                                                    <item.icon className='h-5 w-auto text-gray-500' />
                                                    <motion.p animate={controlText} className='ml-4 w-auto'>{item.name}</motion.p>
                                                    {active && (
                                                        <BsArrowRightShort
                                                            className={classNames(
                                                                open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                                'ml-auto inline-flex h-6 w-auto transform transition-colors ease-in-out duration-150'
                                                            )}
                                                        />)}
                                                </Disclosure.Button>
                                                <Transition
                                                    enter='transition duration-100 ease-out'
                                                    enterFrom='transform scale-95 opacity-0'
                                                    enterTo='transform scale-100 opacity-100'
                                                    leave='transition ease-in duration-100'
                                                    leaveFrom='opacity-100'
                                                    leaveTo='opacity-0'
                                                >
                                                    {active && (
                                                        <Disclosure.Panel className='space-y-1 flex flex-col'>
                                                            {item.children.map((subItem) => (
                                                                <Link
                                                                    key={subItem.name}
                                                                    to={subItem.href}
                                                                    className='w-full flex items-center px-4 py-1 text-xs rounded-md mx-18 hover:bg-ruby hover:text-snow ease-linear transition-all duration-100 text-black-light'
                                                                >
                                                                    <RiArrowDropRightFill
                                                                        className={classNames(
                                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                                            ' flex-shrink-0 h-6 w-6'
                                                                        )}
                                                                        aria-hidden='true'
                                                                    />
                                                                    <motion.p animate={controlText}>{subItem.name}</motion.p>
                                                                </Link>
                                                            ))}
                                                        </Disclosure.Panel>
                                                    )}
                                                </Transition>
                                            </Fragment>
                                        )}
                                    </Disclosure>
                                )
                            ))}
                        </div>
                    </div>
                </motion.div>
            </div >
            {children}
        </Fragment>

    )
}
