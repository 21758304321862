import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
/**Paginas para rutas */
import { Contratos } from '../../../pages/crm/contratos/Contratos'
import { ContratosCreate } from '../../../pages/crm/contratos/ContratosCreate'
import { ContratosEdit } from '../../../pages/crm/contratos/ContratosEdit'


export const ContratosRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Contratos />} />
                <Route path='/create' element={<ContratosCreate />} />
                <Route path='/edit/:id' element={<ContratosEdit />} />
            </Routes>
        </Fragment>
    )
}
