/**Colores para el menu del items  */
export const colorLetraMain = {
    'A': 'bg-ruby text-snow',
    'B': 'bg-ruby text-snow',
    'C': 'bg-ruby text-snow',
    'D': 'bg-ruby text-snow',
    'E': 'bg-ruby text-snow',
    'F': 'bg-blue text-snow',
    'G': 'bg-blue text-snow',
    'H': 'bg-blue text-snow',
    'I': 'bg-blue text-snow',
    'J': 'bg-blue text-snow',
    'K': 'bg-blue text-snow',
    'L': 'bg-emerald text-snow',
    'M': 'bg-emerald text-snow',
    'N': 'bg-agata text-snow',
    'Ñ': 'bg-agata text-snow',
    'O': 'bg-ambar text-snow',
    'P': 'bg-ambar text-snow',
    'Q': 'bg-ambar text-snow',
    'R': 'bg-agata text-snow',
    'S': 'bg-orange-2 text-white',
    'T': 'bg-orange-2 text-white',
    'U': 'bg-orange-2 text-white',
    'V': 'bg-orange-2 text-white',
    'W': 'bg-gray-2 text-snow',
    'X': 'bg-gray-2 text-snow',
    'Y': 'bg-gray-2 text-snow',
    'Z': 'bg-gray-2 text-snow',

}

export const colorLetra = {
    'A': 'text-ruby',
    'B': 'text-ruby',
    'C': 'text-ruby',
    'D': 'text-ruby',
    'E': 'text-ruby',
    'F': 'text-blue',
    'G': 'text-blue',
    'H': 'text-blue',
    'I': 'text-blue',
    'J': 'text-blue',
    'K': 'text-blue',
    'L': 'text-emerald',
    'M': 'text-emerald',
    'N': 'text-agata',
    'Ñ': 'text-agata',
    'O': 'text-ambar',
    'P': 'text-ambar',
    'Q': 'text-ambar',
    'R': 'text-agata',
    'S': 'text-orange-2',
    'T': 'text-orange-2',
    'U': 'text-orange-2',
    'V': 'text-orange-2',
    'W': 'text-gray-2',
    'X': 'text-gray-2',
    'Y': 'text-gray-2',
    'Z': 'text-gray-2',
}