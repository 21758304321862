import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FiSlash, FiAperture } from 'react-icons/fi';

export const SubmitLayout = ({ backRoute, reset, submitName, SubmitIcon }) => {
    return (
        <Fragment>
            <Link
                to={`${backRoute}`}
                className='bg-white mr-2 inline-flex justify-center float-left py-2 px-4 border border-transparent shadow-sm text-sm rounded-md
                    text-blue-dark hover:bg-white-dark focus:outline-none'
            >
                Cancelar <FiSlash className='ml-2 mt-0.5 text-ruby' />
            </Link>


            <button
                type='button'
                onClick={() => reset()}
                className='bg-white mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md
                   text-blue-dark hover:bg-white-dark focus:outline-none'
            >
                Limpiar <FiAperture className='ml-2 mt-0.5 text-gray-3' />
            </button>


            <button
                type='submit'
                className='bg-white mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md
                   text-blue-dark hover:bg-white-dark focus:outline-none'
            >
                {submitName} <SubmitIcon />
            </button>
        </Fragment>
    )
}
