import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiPlus } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/**Validar errores desde el backend */
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';
/**Componenetes */
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { SubmitLayout } from '../../../components/button/group/SubmitLayout';
import { AutocompleteCliente } from '../../../components/autocomplete/AutocompleteCliente';


export const ContratosCreate = () => {

    /**Navegar a otra ruta */
    let navigate = useNavigate();
    /**Manejo de dato en los inputs y el submit */
    const {  handleSubmit, formState: { errors }, reset } = useForm();
    /**Obtener el Token */
    const { token } = CookieService.get('user');
    /**Estado de Loading */
    const [cargando, setCargando] = useState(false);
    /**Manejo de los clientes */
    const [clientes, setClientes] = useState([])
    const [valueCliente, setValueCliente] = useState(clientes.nombres)

    /**Obtener los cliente */
    const getClientes = async () => {
        setCargando(true)
        const url = `${endpoint}/api/clientes/`;
        await axios.get(url, headers(token)).then((response) => {
            /** Asignar Data a la variable */
            setClientes(response.data)
            setCargando(false)
        }).catch((err) => {
            const error = ErrorValidation(err.response);
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
            setCargando(false);
        })
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Funcion de submit */
    const onSubmit = async (data) => {
        setCargando(true)
        let results = {} 
        if (valueCliente !== undefined) {
            results = {cliente: valueCliente.id }
        } 
        const url = `${endpoint}/api/contratos/`;
        await axios.post(url, results, headers(token)).then(() => {
            toast.success(`El contrato fue creado con exito!`, topRight);
            navigate('/contratos')
        }).catch((err) => {
            const error = ErrorValidation(err.response);
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
            setCargando(false);
        })
    };

    /**Manejo de clases de tailwind  */
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    return (
        <Fragment>
            {cargando === true && (
                <LoadingScreen
                    ImgClass={`animate-pulse`}
                    cargando={cargando}
                />
            )}
            <div className='flex flex-col p-10'>
                <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='p-4'>
                        <h1 className='p-2 text-2xl text-blue-dark'>Crear un nuevo contrato</h1>
                        <div className='p-2 text-xs text-gray-dark'>Todos los campos con el <span className='text-ruby font-bold'>(*)</span> son requeridos</div>
                        <hr />
                    </div>



                    <div className='py-2 px-4 align-middle inline-block min-w-full'>
                        <div className={classNames(
                            valueCliente ? 'h-auto' : 'h-56',
                            'px-2 py-8 shadow overflow-hidden border sm:rounded-lg w-full bg-snow'
                        )}
                        >
                            <div className='md:grid md:grid-cols-3 md:gap-6'>
                                <div className='md:col-span-1 mx-2'>
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>Buscar cliente</h3>
                                    <div className='mt-2'>
                                        <AutocompleteCliente
                                            clientes={clientes}
                                            valueCliente={valueCliente}
                                            setValueCliente={setValueCliente}
                                        // validation={register('cliente', { required: true })}
                                        />
                                        {errors.cliente && (
                                            <div className='text-ruby pt-1 text-sm'>El campo cliente es requerido</div>
                                        )}
                                    </div>
                                </div>
                                {valueCliente !== undefined && (

                                    <div className='mt-5 md:mt-0 md:col-span-2'>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className='grid grid-cols-6 gap-6'>
                                                <div className='col-span-6 sm:col-span-3 relative border border-gray-300 rounded-md  shadow-sm'>
                                                    <label
                                                        htmlFor='name'
                                                        className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-snow text-xs font-medium text-gray-900'
                                                    >
                                                        Nombres
                                                    </label>
                                                    <input
                                                        disabled
                                                        type='text'
                                                        id='name'
                                                        className='block w-full p-0 text-gray-900 sm:text-sm px-3 py-2  disabled:opacity-75'
                                                        value={valueCliente.nombres || ''}
                                                        onChange={() => setValueCliente}
                                                    />
                                                </div>

                                                <div className='col-span-6 sm:col-span-3 relative border border-gray-300 rounded-md  shadow-sm'>
                                                    <label
                                                        htmlFor='name'
                                                        className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-snow text-xs font-medium text-gray-900'
                                                    >
                                                        Apellidos
                                                    </label>
                                                    <input
                                                        disabled
                                                        type='text'
                                                        name='name'
                                                        id='name'
                                                        className='block w-full p-0 text-gray-900 sm:text-sm px-3 py-2  disabled:opacity-75'
                                                        value={valueCliente.apellidos || ''}
                                                        onChange={() => setValueCliente}
                                                    />
                                                </div>


                                                <div className='col-span-6 relative border border-gray-300 rounded-md shadow-sm'>
                                                    <label
                                                        htmlFor='name'
                                                        className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-snow text-xs font-medium text-gray-900'
                                                    >
                                                        Identificacion
                                                    </label>
                                                    <input
                                                        disabled
                                                        type='text'
                                                        name='name'
                                                        id='name'
                                                        className='block w-full p-0 text-gray-900 sm:text-sm px-3 py-2  disabled:opacity-75'
                                                        value={valueCliente.identificacion || ''}
                                                        onChange={() => setValueCliente}
                                                    />
                                                </div>

                                                <div className='col-span-6 sm:col-span-3 relative border border-gray-300 rounded-md  shadow-sm'>
                                                    <label
                                                        htmlFor='name'
                                                        className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-snow text-xs font-medium text-gray-900'
                                                    >
                                                        Parroquia
                                                    </label>
                                                    <input
                                                        disabled
                                                        type='text'
                                                        name='name'
                                                        id='name'
                                                        className='block w-full p-0 text-gray-900 sm:text-sm px-3 py-2  disabled:opacity-75'
                                                        value={valueCliente.parroquia || ''}
                                                        onChange={() => setValueCliente}
                                                    />
                                                </div>

                                                <div className='col-span-6 sm:col-span-3 relative border border-gray-300 rounded-md  shadow-sm'>
                                                    <label
                                                        htmlFor='name'
                                                        className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-snow text-xs font-medium text-gray-900'
                                                    >
                                                        Sector
                                                    </label>
                                                    <input
                                                        disabled
                                                        type='text'
                                                        name='name'
                                                        id='name'
                                                        className='block w-full p-0 text-gray-900 sm:text-sm px-3 py-2  disabled:opacity-75'
                                                        value={valueCliente.sector || ''}
                                                        onChange={() => setValueCliente}
                                                    />
                                                </div>

                                                <div className='col-span-6 relative border border-gray-300 rounded-md shadow-sm'>
                                                    <label
                                                        htmlFor='name'
                                                        className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-snow text-xs font-medium text-gray-900'
                                                    >
                                                        Direccion
                                                    </label>
                                                    <input
                                                        disabled
                                                        type='text'
                                                        name='name'
                                                        id='name'
                                                        className='block w-full p-0 text-gray-900 sm:text-sm px-3 py-2  disabled:opacity-75'
                                                        value={valueCliente.direccion || ''}
                                                        onChange={() => setValueCliente}
                                                    />
                                                </div>


                                            </div>

                                            <div className='mt-5 py-2 text-right'>

                                                <SubmitLayout
                                                    backRoute={`/contratos`}
                                                    reset={reset}
                                                    submitName={`Crear`}
                                                    SubmitIcon={() => <FiPlus className='ml-2 mt-0.5 text-emerald' />}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
