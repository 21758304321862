import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**paginas para las rutas */
import { Unidades } from '../../../pages/almacen/unidades/Unidades'
import { UnidadesCreate } from '../../../pages/almacen/unidades/UnidadesCreate'
import { UnidadesEdit } from '../../../pages/almacen/unidades/UnidadesEdit'

export const UnidadesRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Unidades />} />
                <Route path='/create' element={<UnidadesCreate />} />
                <Route path='/edit/:id' element={<UnidadesEdit />} />
            </Routes>
        </Fragment>
    )
}
