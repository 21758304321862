import { Fragment, useTransition } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiPlus } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Validacion de errores */
import { ErrorValidation } from '../../../constants/helpers/ErrorValidation';
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/** componentes */
import { FloatInputText } from '../../../components/inputs/FloatInputText';
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { SubmitLayout } from '../../../components/button/group/SubmitLayout';

export const FabricantesCreate = () => {
  /**Navegar a otra ruta */
  let navigate = useNavigate();

  /**Manejo de dato en los inputs y el submit */
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  /**Obtener el Token */
  const { token } = CookieService.get('user');

  /**Estado de Loading */
  const [isPending, startTransition] = useTransition();

  /**Funcion de submit */
  const onSubmit = (data) => {
    startTransition(async () => {
      const url = `${endpoint}/api/fabricantes/`;
      try {
        const response = await axios.post(url, data, headers(token));
        toast.success(`El Fabricante ${response.data.codigo} fue creado con exito!`, topRight);
        navigate('/fabricantes')
      } catch (err) {
        const error = ErrorValidation(err.response);
        toast.warning(`Opps, Ha ocurrido un error, ${error}`, topRight);
      }
    });
  }

  return (
    <Fragment>
      {isPending === true && (
        <LoadingScreen
          ImgClass={`animate-pulse`}
          cargando={isPending}
        />
      )}
      <div className='flex flex-col p-10'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='p-4'>
            <h1 className='p-2 text-2xl text-blue-dark'>Crear un nuevo Fabricante</h1>
            <div className='p-2 text-xs text-gray-dark'>Todos los campos con el <span className='text-ruby font-bold'>(*)</span> son requeridos</div>
            <hr />
          </div>
          <div className='py-2 px-4 align-middle inline-block min-w-full'>
            <div className='px-4 py-5  shadow overflow-hidden border sm:rounded-lg w-full bg-snow'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-6 gap-6'>

                  {/* Input Codigo */}
                  <div className='col-span-6 sm:col-span-2 relative'>
                    <FloatInputText
                      validation={register('codigo', { required: true })}
                      name={'Codigo'}
                      showName={'Codigo'}
                    />
                    {errors.codigo && (
                      <div className='text-ruby pt-1 text-sm'>El campo Codigo es requerido</div>
                    )}
                  </div>


                </div>

                <div className='mt-2 py-2 text-right'>
                  {/* Botones de Cancelar, Limpiar y Submit */}
                  <SubmitLayout
                    backRoute={`/fabricantes`}
                    reset={reset}
                    submitName={`Crear`}
                    SubmitIcon={() => <FiPlus className='ml-2 mt-0.5 text-emerald' />}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
