import React from 'react'
import { Link } from 'react-router-dom';
/**Iconos */
import { PlusIcon as PlusIconSolid } from '@heroicons/react/solid';

import '../../styles/Abutton.css';

export const ButtonLink = ({ nombre, ruta, buttonStyle }) => {
    return (
        <div className="wrapper mt-4">
            <Link
                className={`button ${buttonStyle}`}
                to={ruta}
            >
                <div className="icon">
                    <PlusIconSolid width={20} aria-hidden="true" />
                </div>
                <span>
                    {nombre}
                </span>
            </Link>
        </div>
    )
}
