/** Mensaje de Bienvenida */
export function msjBienvenida() {

    let ahora = new Date();
    let horas = ahora.getHours();
    let mensaje = "";

    if (horas > 4) mensaje = "Buenos días";
    if (horas > 12) mensaje = "Buenas tardes";
    if (horas > 19) mensaje = "Buenas noches";
    return mensaje;
}