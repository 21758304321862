import React, { useState, useContext } from 'react'
/** Libreria de Imagenes */
import { images } from '../../constants/images'
/** React Hook Form */
import { useForm } from 'react-hook-form';
/** Iconos */
import { HiMail } from 'react-icons/hi';
import { FcKey } from 'react-icons/fc';
import { GoEye, GoEyeClosed } from "react-icons/go";
/** Libreria de Notificaciones */
import { toast } from 'react-toastify';
import { topRight } from '../../constants/helpers/toastOptions';
/** Url del Backend */
import { endpoint } from '../../constants/endpoint';
/** Reducers */
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/types';
/** Axios */
import axios from 'axios';

export const Login = () => {
    const { register, handleSubmit } = useForm();
    /** Trigger para el guardado de la informacion en localstorage */
    const { dispatch } = useContext(AuthContext)

    /** Trigger para ver la password que se introduzca */
    const [show, setShow] = useState(false)
    /** Cargando */
    const [cargando, setCargando] = useState(false);

    const onSubmit = async (data) => {
        setCargando(true)
        const url = `${endpoint}/login/`;
        await axios.post(url, data).then(response => {
            const { user, token } = response.data;
            let newData = {
                user: user,
                token: token
            }
            toast.success(`Usuario ${user.nombre} logueado con exito!`, { ...topRight, icon: () => <img src={images.logoNetwork} alt='' /> });
            setCargando(false)
            const action = {
                type: types.login,
                payload: newData
            }
            dispatch(action)
        }).catch(() => {
            setCargando(false)
        })
    }

    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 h-screen w-full'>
            <div className='hidden sm:block'>
                <img className='w-full h-full object-cover' src={images.fondo4} alt='' />
            </div>

            <div className='bg-gray-800 flex flex-col justify-center'>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='max-w-[400px] w-full mx-auto bg-gray-900 p-8 px-8 rounded-lg'>
                    <h2 className='text-4xl dark:text-snow font-bold text-center'>Inicio de Sesión</h2>

                    {/* Input de Username */}
                    <div className='flex flex-col text-snow py-4 '>
                        <label className='inline-flex' htmlFor='email'>
                            <HiMail className='mt-1 mr-1' /> Correo
                        </label>
                        <input
                            className='appearance-none w-full py-3 px-3 leading-tight focus:ring-0 focus:shadow-lg pr-16 font-light rounded-lg bg-gray-700 mt-2 p-2 
                         focus:bg-gray-500 focus:outline-none'
                            type='email'
                            {...register('username')}
                            defaultValue={'test@gmail.com'}
                        />
                    </div>
                    {/* Fin Input de Username  */}


                    {/* Input password */}
                    <div className='flex flex-col text-snow'>
                        <label className='font-semibold mb-1 text-gray-700 inline-flex ' htmlFor='email'>
                            <FcKey className='mt-1 mr-1' /> Contraseña
                        </label>

                        <div className='relative w-full'>
                            <div className='absolute inset-y-0 mt-2 right-0 flex items-center px-2 transition-all'>
                                {show ? (
                                    <button type={`button`} className='mr-2' onClick={() => setShow(false)}>
                                        <GoEye className='text-gray-2 text-xl' />
                                    </button>
                                ) : (
                                    <button type={`button`} className='mr-2' onClick={() => setShow(true)}>
                                        <GoEyeClosed className='text-gray-2 text-xl' />
                                    </button>
                                )}
                            </div>
                            <input
                                className='rounded-lg bg-gray-700 mt-2 p-2  focus:bg-gray-500 appearance-none w-full py-3 px-3 leading-tight focus:ring-0
                                            focus:shadow-lg  focus:outline-none text-gray-700 pr-16'
                                type={`${show ? 'text' : 'password'}`}
                                autoComplete='off'
                                {...register(`${'password'}`, { required: true })}
                                defaultValue={`test`}
                            />
                        </div>
                    </div>
                    {/* Fin Input Password */}


                    <div className='flex justify-between text-snow py-2 mt-2'>
                        <p className='flex items-center'><input className='mr-2' type='checkbox' />Recuerdame</p>
                        <p>Olvide mi contraseña</p>
                    </div>


                    <div className="pt-2">
                        {cargando === false ? (
                            <button
                                className='w-full my-5 py-2 bg-red-500 shadow-lg shadow-red-500/50 hover:shadow-red-500/40 text-snow font-semibold rounded-lg'>
                                Entrar
                            </button>
                        ) : (
                            <button
                                disabled
                                className='w-full my-5 bg-red-500 shadow-lg shadow-red-500/50 hover:shadow-red-500/40 text-snow font-semibold 
                                    bg-blue-dark group relative flex items-center justify-center py-2 px-4 border border-transparent rounded-lg
                                    focus:outline-none focus:ring-2 transition ease-out duration-500'>
                                <div className='mr-2'>
                                    <div className='w-6 h-6 border-b-2 border-white rounded-full animate-spin'></div>
                                </div>
                                Cargando...
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
