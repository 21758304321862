import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'

export const AutocompleteComponente = ({ articulos, valueArticulo, setValueArticulo }) => {


    const [query, setQuery] = useState('')

    const filteredArticulos =
        query === ''
            ? articulos
            : articulos.filter((item) =>
                item.modelo_articulo
                    .toLowerCase()
                    .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                    .includes(query.toLowerCase())


            )

    return (
        <Combobox as='div' value={valueArticulo} onChange={setValueArticulo}>
            <div className='relative mt-1'>
                <div className='relative w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none sm:text-sm overflow-hidden'>
                    <Combobox.Input
                        className='block p-2.5 w-full text-sm appearance-none focus:outline-none bg-transparent border focus:border-blue-900 border-gray-300 rounded-md'
                        displayValue={(item) => item.modelo_articulo}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder='escriba aqui '
                        autoComplete='off'
                    />
                </div>
                <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options static className='absolute  w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-24 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm '>
                        {filteredArticulos.length === 0 && query !== '' ? (
                            <div className='cursor-default select-none relative py-2 px-4 text-gray-700'>
                                No hay resultados
                            </div>
                        ) : (
                            filteredArticulos.map((item) => (
                                <Combobox.Option
                                    key={item.id}
                                    className={({ active }) =>
                                        `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? 'text-white bg-red-600' : 'text-gray-900'}`
                                    }
                                    value={item}
                                >
                                    {({ selected, active }) => (
                                        <Fragment>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                            >
                                                {item.tipo_articulo + ' - ' + item.modelo_articulo}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-red-600'}`}
                                                >
                                                    <CheckIcon className='w-5 h-5' aria-hidden='true' />
                                                </span>
                                            ) : null}
                                        </Fragment>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>

            </div>
        </Combobox>
    )

}
