import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FiSlash } from 'react-icons/fi';

export const ModalInventarioShow = ({ open, setOpen, data }) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block align-bottom bg-snow rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full'>

                            {/* Inicio Content */}
                            <div className="bg-snow shadow overflow-hidden sm:rounded-lg">
                                {/* Header */}
                                <div className="px-4 py-5 sm:px-6 bg-blue-dark text-snow">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Descripción del Articulo</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{data.articulo_descripcion}</p>
                                </div>

                                {/* Body */}
                                <div className="border-t border-gray-200 px-4 pt-5 pb-10 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Condición</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{data.condicion}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Estatus</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{data.estatus}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Ubicación</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{data.ubicacion}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fecha</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{data.fecha}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Serial</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{data.serial !== null ? (data.serial) : (<FiSlash className='text-ruby' />)}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Mac Address</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{data.direccion_mac !== null ? (data.direccion_mac) : (<FiSlash className='text-ruby' />)}</dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Descripción</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {data.descripcion}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                            {/* Fin Content */}

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
