import { useEffect, useReducer } from 'react';
/** Funciones para globalizar variables */
import { authReducer } from './auth/authReducer';
import { AuthContext } from './auth/authContext';
/** Archivo de Rutas Principal */
import { AuthRouter } from './routers/AuthRouter'
/** Toast */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/** Cookie Service */
import CookieService from './services/CookieService';


const init = () => {
    return CookieService.get('user') || { logged: false }
}

export const MikroNetwork = () => {
    const [user, dispatch] = useReducer(authReducer, {}, init)

    useEffect(() => {
        if (!user) return;
        /** Cookie settings */
        const expiresAt = 60 * 24;
        let date = new Date();
        date.setTime(date.getTime() + (expiresAt * 60 * 1000));

        const options = { path: '/', expiresAt: date };
        CookieService.set('user', JSON.stringify(user), options)
    }, [user])

    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AuthRouter />
            <ToastContainer />
        </AuthContext.Provider>
    )
}
