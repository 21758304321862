import { BsTv, BsFillStarFill, BsFillFilePersonFill, BsWifi, BsCalendar3Week, BsCurrencyExchange, BsBoxSeam, BsStickies, BsChatText, BsTools, BsHouseDoor } from 'react-icons/bs'

/**Navegacion del Sidebar */
export const navigationSidebar = [
    { name: 'Inicio', href: '#', icon: BsHouseDoor, },
    // {
    //     name: 'Gestion de red', icon: BsTv,
    //     children: [
    //         { name: 'Overview', href: '#' },
    //         { name: 'Members', href: '#' },
    //         { name: 'Calendar', href: '#' },
    //         { name: 'Settings', href: '#' },
    //     ],
    // },
    // {
    //     name: 'Servicios', icon: BsFillStarFill,
    //     children: [
    //         { name: 'Overview', href: '#' },
    //         { name: 'Members', href: '#' },
    //         { name: 'Calendar', href: '#' },
    //         { name: 'Settings', href: '#' },
    //     ],
    // },
    {
        name: 'CRM', icon: BsFillFilePersonFill,
        children: [
            { name: 'Clientes', href: '/clientes' },
            { name: 'Parroquias', href: '/parroquias' },
            { name: 'Sectores', href: '/sectores' },
            // { name: 'Contratos', href: '/contratos' },
        ],
    },
    // {
    //     name: 'Fichas Hotpot', icon: BsWifi,
    //     children: [
    //         { name: 'Overview', href: '#' },
    //         { name: 'Members', href: '#' },
    //         { name: 'Calendar', href: '#' },
    //         { name: 'Settings', href: '#' },
    //     ],
    // },
    // { name: 'Tareas', href: '#', icon: BsCalendar3Week, },
    // {
    //     name: 'Fianzas', icon: BsCurrencyExchange,
    //     children: [
    //         { name: 'Overview', href: '#' },
    //         { name: 'Members', href: '#' },
    //         { name: 'Calendar', href: '#' },
    //         { name: 'Settings', href: '#' },
    //     ],
    // },
    {
        name: 'Almancen', icon: BsBoxSeam,
        children: [
            { name: 'Inventario', href: '/inventario' },
            { name: 'Articulos', href: '/articulos' },
            { name: 'Fabricantes', href: '/fabricantes' },
            { name: 'Marcas', href: '/marcas' },
            { name: 'Tipos', href: '/tipos' },
            { name: 'Unidades', href: '/unidades' },
            { name: 'Kits', href: '/kits' },


        ],
    },
//     {
//         name: 'Tickets', icon: BsStickies,
//         children: [
//             { name: 'Overview', href: '#' },
//             { name: 'Members', href: '#' },
//             { name: 'Calendar', href: '#' },
//             { name: 'Settings', href: '#' },
//         ],
//     },
//     {
//         name: 'Mensajeria', icon: BsChatText,
//         children: [
//             { name: 'Overview', href: '#' },
//             { name: 'Members', href: '#' },
//             { name: 'Calendar', href: '#' },
//             { name: 'Settings', href: '#' },
//         ],
//     },
//     { name: 'Ajustes', href: '#', icon: BsTools, },
]
