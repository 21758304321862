import { useState, Fragment } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form';
/**Axios */
import axios from 'axios'
/**Rutas de acceso */
import { endpoint } from '../../../constants/endpoint'
/**Iconos */
import { FiEdit3 } from 'react-icons/fi';
/**Cookies y servicios */
import CookieService from '../../../services/CookieService';
import { headers } from '../../../services/HttpHeaders'
/**Toast */
import { toast } from 'react-toastify';
import { topRight } from '../../../constants/helpers/toastOptions';
/** Float Input Component (Personalizado) */
import { FloatInputText } from '../../../components/inputs/FloatInputText';
import { LoadingScreen } from '../../../components/loadings/LoadingScreen';
import { SubmitLayout } from '../../../components/button/group/SubmitLayout';

export const FabricantesEdit = () => {
  /**Navegar a otra ruta */
  let navigate = useNavigate();
  /**Location del objecto para editar*/
  const { state } = useLocation()

  /**Manejo de dato en los inputs y el submit */
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  /**Obtener el Token */
  const { token } = CookieService.get('user');

  /**Estado de Loading */
  const [cargando, setCargando] = useState(false);

  /**Funcion de submit */
  const onSubmit = async (data) => {
    setCargando(true)
    const url = `${endpoint}/api/fabricantes/${state.id}/`;
    await axios.patch(url, data, headers(token)).then(() => {
      toast.success(`El Fabricante ${data.codigo} fue editado con exito!`, topRight);
      navigate('/fabricantes')
    }).catch((err) => {
      console.log(err.response)
      toast.warning(`Opps, Ha ocurrido un error`, topRight);
      setCargando(false);
    })
  };

  return (
    <Fragment>
      {cargando === true && (
        <LoadingScreen
          ImgClass={`animate-pulse`}
          cargando={cargando}
        />
      )}
      <div className='flex flex-col p-10'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='p-4'>
            <h1 className='p-2 text-2xl text-blue-dark'>Editar el Fabricante {state.codigo}</h1>
            <div className='p-2 text-xs text-gray-dark'>Todos los campos con el <span className='text-ruby font-bold'>(*)</span> son requeridos</div>
            <hr />
          </div>
          <div className='py-2 px-4 align-middle inline-block min-w-full'>
            <div className='px-4 py-5  shadow overflow-hidden border sm:rounded-lg w-full bg-snow'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-6 gap-6'>

                 {/* Input Codigo */}
                 <div className='col-span-6 sm:col-span-2 relative'>
                    <FloatInputText
                      validation={register('codigo', { required: true })}
                      name={'Codigo'}
                      showName={'Codigo'}
                      value={state.codigo}
                    />
                    {errors.codigo && (
                      <div className='text-ruby pt-1 text-sm'>El campo Nombre es requerido</div>
                    )}
                  </div>

                </div>

                <div className='mt-2 py-2 text-right'>
                  <SubmitLayout
                    backRoute={`/fabricantes`}
                    reset={reset}
                    submitName={`Editar`}
                    SubmitIcon={() => <FiEdit3 className='ml-2 mt-0.5 text-blue' />}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
