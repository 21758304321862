import { useEffect, useState } from 'react'
import { TrashIcon, PlusIcon } from '@heroicons/react/solid'
import axios from 'axios'
import { headers } from '../../../services/HttpHeaders'
import { endpoint } from '../../../constants/endpoint'
import { toast } from 'react-toastify'
import { topRight } from '../../../constants/helpers/toastOptions'
import CookieService from '../../../services/CookieService'
import { AutocompleteCliente } from '../../../components/autocomplete/AutocompleteCliente'
import { FloatInputText } from '../../../components/inputs/FloatInputText'
// import { useForm } from 'react-hook-form'
import { AutocompleteComponente } from '../../../components/autocomplete/AutocompleteComponente'


export const Kits = () => {

    // const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    /**Estado de articulos */
    const [articulos, setArticulos] = useState([]);
    const [valueArticulo, setValueArticulo] = useState(articulos.modelo_articulo)
    /**Estaod de los clientes  */
    const [clientes, setClientes] = useState([])
    const [valueCliente, setValueCliente] = useState(clientes.nombres)

    /**obtener el token */
    const { token } = CookieService.get('user');
    /**Funcion para obtener los articulos */
    const getArticulos = async () => {
        const url = `${endpoint}/api/articulos/`;
        await axios.get(url, headers(token)).then((response) => {
            /** Asignar Data a la variable */
            setArticulos(response.data);
        }).catch((err) => {
            toast.warning(`Opps, Ha ocurrido un error`, topRight);
        })
    }

    /**Funcion para obtener los clientes */
    const getClientes = async () => {
        const url = `${endpoint}/api/clientes/`;
        await axios.get(url, headers(token)).then((response) => {
            /** Asignar Data a la variable */
            setClientes(response.data);
        }).catch((err) => {
            toast.warning(`Opps, Ha ocurrido un error`, topRight);
        })
    }

    useEffect(() => {
        getArticulos();
        getClientes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**inicio de la logica para agregar al kit */

    // const [cantidad, setCantidad] = useState([])
    // const [filtroArticulo, setFiltroArticulo] = useState([])

    // const addKit = (valueArticulo) => {
    //     let item = articulos.find((i) => i.id === valueArticulo.id)

    //     if (!item) {
    //         const component = {
    //             id: valueArticulo.id,
    //             descripcion: valueArticulo.descripcion,
    //             fabricante: valueArticulo.fabricante,
    //             marca_articulo: valueArticulo.marca_articulo,
    //             modelo_articulo: valueArticulo.modelo_articulo,
    //             tipo_articulo: valueArticulo.tipo_articulo,
    //             consumible: valueArticulo.consumible,
    //         };
    //         setFiltroArticulo([...filtroArticulo, component])
    //     }
    // }



    /**Fin de la logica */
    // const [selectedArticulos, setSelectedArticulos] = useState([])

    // const addArticulos = () => {
    //     const articulo_id = valueArticulo;
    //     const filterArticulo = articulos.filter((value) => {
    //         return value.id == articulo_id;
    //     });


    //     const e = filterArticulo[0];
    //     const array = [...articulos]; // make a separate copy of the array
    //     const index = array.indexOf(e);

    //     if (index !== -1) {
    //         array.splice(index, 1);
    //         setSelectedArticulos(array);
    //     }
    //     setSelectedArticulos(selectedArticulos.concat(e));
    // }

    // const removeProducts = (e) => {
    //     const array = [...selectedArticulos]; // make a separate copy of the array
    //     const index = array.indexOf(e);

    //     if (index !== -1) {
    //         array.splice(index, 1);
    //         setSelectedArticulos(array);
    //     }
    // };

    return (
        <div className='bg-gray-50'>
            <div className='max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8'>


                <form className='lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16'>
                    <div>
                        <div>
                            <h2 className='text-lg font-medium text-gray-900'>Buscar Contrato</h2>
                            <div className='mt-4'>
                                <AutocompleteCliente
                                    clientes={clientes}
                                    valueCliente={valueCliente}
                                    setValueCliente={setValueCliente}
                                />
                            </div>
                        </div>

                        <div className='mt-10 border-t border-gray-200 pt-10'>
                            <h2 className='text-lg font-medium text-gray-900'>{valueCliente !== undefined && ('Informacion de contrato')}</h2>

                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

                                {valueCliente !== undefined && (
                                    <div className='relative'>
                                        <FloatInputText
                                            // validation={register('descripcion', { required: true })}
                                            name={'Nombres'}
                                            showName={'Nombres'}
                                            value={valueCliente.nombres}
                                        />
                                    </div>
                                )}

                                {valueCliente !== undefined && (
                                    <div className='relative'>
                                        <FloatInputText
                                            // validation={register('descripcion', { required: true })}
                                            name={'Apellidos'}
                                            showName={'Apellidos'}
                                            value={valueCliente.apellidos}
                                        />
                                    </div>
                                )}


                                {valueCliente !== undefined && (
                                    <div className="sm:col-span-2 relative">
                                        <FloatInputText
                                            // validation={register('descripcion', { required: true })}
                                            name={'Identificacion'}
                                            showName={'Identificacion'}
                                            value={valueCliente.identificacion}
                                        />
                                    </div>
                                )}

                                {valueCliente !== undefined && (
                                    <div className="relative">
                                        <FloatInputText
                                            // validation={register('descripcion', { required: true })}
                                            name={'Parroquia'}
                                            showName={'Parroquia'}
                                            value={valueCliente.parroquia}
                                        />
                                    </div>
                                )}

                                {valueCliente !== undefined && (
                                    <div className=" relative">
                                        <FloatInputText
                                            // validation={register('descripcion', { required: true })}
                                            name={'Sector'}
                                            showName={'Sector'}
                                            value={valueCliente.sector}
                                        />
                                    </div>
                                )}

                                {valueCliente !== undefined && (
                                    <div className="sm:col-span-2 relative">
                                        <FloatInputText
                                            // validation={register('descripcion', { required: true })}
                                            name={'Direccion'}
                                            showName={'Direccion'}
                                            value={valueCliente.direccion}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>



                        {/* Payment */}
                        <div className='mt-6 border-t pt-6 border-gray-200'>
                            <h2 className='text-lg font-medium text-gray-900'>Componentes</h2>



                            <div className='mt-6 grid grid-cols-4 gap-y-6 gap-x-4'>
                                <div className='col-span-4'>
                                    {/* <select
                                        id='articulos'
                                        autoComplete='articulos'
                                        className='block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                        {...register('articulo', { required: true })}
                                    >
                                        <option value=''>Seleccione un componente</option>
                                        {articulos.map((item) => (
                                            <option value={item.id} key={item.id}>
                                                {item.modelo_articulo}
                                            </option>
                                        ))}
                                    </select>
                                 
                                    <div className="px-2 py-2 text-justify bg-gray-50 sm:px-6">
                                        <button
                                            type="button"
                                            onClick={() => addProduct(articulos)}
                                            className="mt-4 mr-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        >
                                            <div> <CheckIcon className='h-5 w-5 text-emerald' /></div>
                                        </button>
                                    </div> */}
                                    <AutocompleteComponente
                                        articulos={articulos}
                                        valueArticulo={valueArticulo}
                                        setValueArticulo={setValueArticulo}
                                    />
                                </div>



                            </div>
                        </div>
                        {valueArticulo !== undefined && (

                            <div className='flex py-6 px-4 sm:px-6 border-2 mt-6'>
                                <div className='flex-shrink-0'>
                                    {/* <img src={valueArticulo.imageSrc} alt={valueArticulo.imageAlt} className='w-20 rounded-md' /> */}
                                    <TrashIcon className='h-5 w-5' aria-hidden='true' />
                                </div>

                                <div className='ml-6 flex-1 flex flex-col'>
                                    <div className='flex'>
                                        <div className='min-w-0 flex-1'>
                                            <h4 className='text-sm'>
                                                <div className='font-medium text-gray-700 hover:text-gray-800'>
                                                    {valueArticulo.tipo_articulo}
                                                </div>
                                            </h4>
                                            <p className='mt-1 text-sm text-gray-500'>{valueArticulo.modelo_articulo}</p>
                                            <p className='mt-1 text-sm text-gray-500'>{valueArticulo.fabricante}</p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flow-root'>
                                            <button
                                                type='button'
                                                // onClick={() => addArticulos(valueArticulo)}
                                                className='-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500'
                                            >
                                                <span className='sr-only'>Remove</span>
                                                <PlusIcon className='h-5 w-5' aria-hidden='true' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}

                    </div>


                    {/* Order summary */}
                    <div className='mt-10 lg:mt-0'>
                        <h2 className='text-lg font-medium text-gray-900'>Componentes del Kit</h2>

                        <div className='mt-4 bg-white border border-gray-200 rounded-lg shadow-sm'>
                            {/* <ul role='list' className='divide-y divide-gray-200'>
                                {selectedArticulos?.map((item) => (
                                    <li key={item.id} className='flex py-6 px-4 sm:px-6'>
                                        <div className='flex-shrink-0'>
                                           
                                        </div>

                                        <div className='ml-6 flex-1 flex flex-col'>
                                            <div className='flex'>
                                                <div className='min-w-0 flex-1'>
                                                    <h4 className='text-sm'>
                                                        <div className='font-medium text-gray-700 hover:text-gray-800'>
                                                            {item.tipo_articulo}
                                                        </div>
                                                    </h4>
                                                    <p className='mt-1 text-sm text-gray-500'>{item.modelo_articulo}</p>
                                                    <p className='mt-1 text-sm text-gray-500'>{item.fabricante}</p>
                                                </div>

                                                <div className='ml-4 flex-shrink-0 flow-root'>
                                                    <button
                                                        type='button'
                                                        onClick={() => removeProducts(item)}
                                                        className='-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500'
                                                    >
                                                        <span className='sr-only'>Remove</span>
                                                        <TrashIcon className='h-5 w-5' aria-hidden='true' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul> */}
                            {/* <dl className='border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6'>
                                <div className='flex items-center justify-between'>
                                    <dt className='text-sm'>Subtotal</dt>
                                    <dd className='text-sm font-medium text-gray-900'>$64.00</dd>
                                </div>
                                <div className='flex items-center justify-between'>
                                    <dt className='text-sm'>Shipping</dt>
                                    <dd className='text-sm font-medium text-gray-900'>$5.00</dd>
                                </div>
                                <div className='flex items-center justify-between'>
                                    <dt className='text-sm'>Taxes</dt>
                                    <dd className='text-sm font-medium text-gray-900'>$5.52</dd>
                                </div>
                                <div className='flex items-center justify-between border-t border-gray-200 pt-6'>
                                    <dt className='text-base font-medium'>Total</dt>
                                    <dd className='text-base font-medium text-gray-900'>$75.52</dd>
                                </div>
                            </dl> */}

                            <div className='border-t border-gray-200 py-6 px-4 sm:px-6'>
                                <button
                                    type='submit'
                                    className='w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500'
                                >
                                    Confirm order
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}
