import { BrowserRouter, Route, Routes } from 'react-router-dom'

/**Rutas privadas y publicas */
import { PrivateRoute } from '../auth/PrivateRoute';
import { PublicRoute } from '../auth/PublicRoute';

/**Rutas principales */
import { Login } from '../pages/auth/Login';
import { Main } from './Main';

export const AuthRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                } />

                <Route path="/*" element={
                    <PrivateRoute>
                        <Main />
                    </PrivateRoute>
                } />
            </Routes>

        </BrowserRouter>
    )
}
