import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
/**Paginas para rutas */
import { Sectores } from '../../../pages/crm/sectores/Sectores'
import { SectoresCreate } from '../../../pages/crm/sectores/SectoresCreate'
import { SectoresEdit } from '../../../pages/crm/sectores/SectoresEdit'

export const SectoresRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Sectores />} />
                <Route path='/create' element={<SectoresCreate />} />
                <Route path='/edit/:id' element={<SectoresEdit />} />
            </Routes>
        </Fragment>
    )
}
