import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'

/**Rutas para las paginas */
import { Inventario } from '../../../pages/almacen/inventarios/Inventario'
import { InventarioCreate } from '../../../pages/almacen/inventarios/InventarioCreate'
import { InventarioEdit } from '../../../pages/almacen/inventarios/InventarioEdit'

export const InventarioRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Inventario />} />
                <Route path='/create' element={<InventarioCreate />} />
                <Route path='/edit/:id' element={<InventarioEdit />} />
            </Routes>
        </Fragment>
    )
}
